import React, { useState } from "react";
import {
	AntdInput,
	AntdTable,
	BodyContainer,
	CompaginationDiv,
	Container,
	FilterContainer,
	HeaderContainer,
	Section,
	StepContainer,
	TextContainer,
} from "./styles";
import { COLORS, SIZES, Typo } from "../../../../../../../layout/theme";
import { Button, DatePicker, Select, Spin } from "antd";
import {
	CalendarOutlined,
	InfoCircleOutlined,
	InfoOutlined,
	LeftOutlined,
	RightOutlined,
} from "@ant-design/icons";
import { CONSTANTS } from "../../../../../../../../utils/constants";
import { numberWithDotAndCommas } from "../../../../../../../../utils/numberWithDotAndComas";

export default function Step1UserList({
	setKey,
	rangeDate,
	handleSelectedDate,
	selectedSeller,
	handleSelectSeller,
	sellers,
	handleDeleteFilters,
	sellerList,
	isLoading,
	searchInfo,
	handleDecrement,
	handlePageChange,
	handleIncrement,
	paginationInputValue,
	setPaginationInputValue,
	handleChangeStep,
}) {
	const [toggleMonthData, setToggleMonthData] = useState(false);

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Usuario
				</div>
			),
			width: 350,
			dataIndex: "firstName",
			render: (value, record) => (
				<TextContainer
					onMouseOver={() => setKey(record?.id)}
					onClick={() =>
						handleChangeStep(
							record?.id,
							CONSTANTS.ERP_MODULE_USERS_STEP_2,
							record?.clientId,
							""
						)
					}
					pointer
				>
					<Typo level={6} type="primary">
						{value} {record?.lastName}
					</Typo>
				</TextContainer>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Email
				</div>
			),
			width: 300,
			dataIndex: "email",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Teléfono
				</div>
			),
			dataIndex: "phone",
			render: (value, record) => (
				<TextContainer>
					<Typo level={7} type="muted">
						{value}
					</Typo>
				</TextContainer>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Secondary,
						fontSize: SIZES.level7,
						cursor: "pointer",
						display: "flex",
						justifyContent: "center",
						gap: 5,
						alignItems: "center",
					}}
					onClick={() => setToggleMonthData((prev) => !prev)}
				>
					{toggleMonthData ? "Ventas Totales" : "Ventas mes actual"}
					<CalendarOutlined
						size={10}
						style={{ color: COLORS.Primary }}
					></CalendarOutlined>
				</div>
			),
			dataIndex: "totalSales",
			render: (value, record) => (
				<TextContainer style={{ display: "flex", justifyContent: "center" }}>
					{toggleMonthData ? (
						<Typo level={7} type="terciary">
							$ {numberWithDotAndCommas(value)}
						</Typo>
					) : (
						<Typo level={7} type="terciary">
							$ {numberWithDotAndCommas(record?.monthSales)}
						</Typo>
					)}
				</TextContainer>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Secondary,
						fontSize: SIZES.level7,
						cursor: "pointer",
						display: "flex",
						justifyContent: "center",
					}}
					onClick={() => setToggleMonthData((prev) => !prev)}
				>
					{toggleMonthData ? "Unidades Totales" : "Unidades mes actual"}
				</div>
			),
			dataIndex: "totalUnits",
			render: (value, record) => (
				<TextContainer style={{ display: "flex", justifyContent: "center" }}>
					{toggleMonthData ? (
						<Typo level={7} type="secondary">
							{value}
						</Typo>
					) : (
						<Typo level={7} type="secondary">
							{record?.monthUnits}
						</Typo>
					)}
				</TextContainer>
			),
		},
		{
			title: () => (
				<div
					style={{
						color: COLORS.Secondary,
						fontSize: SIZES.level7,
						cursor: "pointer",
						display: "flex",
						justifyContent: "center",
					}}
					onClick={() => setToggleMonthData((prev) => !prev)}
				>
					{toggleMonthData ? "Ordenes Totales" : "Ordenes mes actual"}
				</div>
			),
			dataIndex: "totalOrders",
			render: (value, record) => (
				<TextContainer style={{ display: "flex", justifyContent: "center" }}>
					{toggleMonthData ? (
						<Typo level={7} type="primary">
							{value}
						</Typo>
					) : (
						<Typo level={7} type="primary">
							{record?.monthOrders}
						</Typo>
					)}
				</TextContainer>
			),
		},
	];

	return (
		<StepContainer>
			<HeaderContainer>
				<Container>
					<FilterContainer>
						<Typo type="secondary" level={6}>
							Vendedor
						</Typo>
						<Select
							showSearch
							allowClear
							placeholder={isLoading ? <Spin size="small" /> : "Vendedor"}
							optionFilterProp="children"
							value={selectedSeller}
							onChange={(e, o) => handleSelectSeller(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={sellers}
							style={{ width: 200 }}
							disabled={isLoading}
						/>
					</FilterContainer>
				</Container>
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button
						type="primary"
						onClick={handleDeleteFilters}
						disabled={isLoading}
					>
						Borrar filtros
					</Button>
				</Container>
			</HeaderContainer>
			<BodyContainer>
				<AntdTable
					dataSource={sellerList}
					loading={isLoading}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
				{searchInfo && searchInfo.totalSellers > 0 && (
					<CompaginationDiv>
						{searchInfo.actualPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						<AntdInput
							step={1}
							min={1}
							max={searchInfo.totalPages}
							onPressEnter={(e) => handlePageChange(e)}
							onChange={(e) =>
								setPaginationInputValue(e.target.value.toString())
							}
							defaultValue={paginationInputValue.toString()}
							value={paginationInputValue.toString()}
							addonAfter={`/ ${searchInfo.totalPages}`}
							style={{
								width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
							}}
						/>
						{searchInfo.actualPage < searchInfo.totalPages && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={
									searchInfo.actualPage === searchInfo.totalPages ? true : false
								}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</BodyContainer>
		</StepContainer>
	);
}
