import React, { useEffect, useState } from "react";
import { AntdButton, Container, InnerForm, Section } from "./styles";
import { Checkbox, Col, Form, Input, Modal, Row, Select } from "antd";
import { get_provinces } from "../../../../../../api/endpoints/region";
import {
	TYPE,
	openNotificationWithIcon,
} from "../../../../../../utils/notificationToast";
import { CloseCircleOutlined } from "@ant-design/icons";
import { COLORS, Typo } from "../../../../../layout/theme";
import { CONSTANTS } from "../../../../../../utils/constants";
import { validateCUIT } from "../../../../../../utils/cuitValidator";
import {
	get_cuit_info,
	send_password_reset_email,
} from "../../../../../../api/endpoints/auth";
import PhoneInput from "../../../../../common/phone-input/PhoneInput";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import { create_client_from_predefined_pointOfSale } from "../../../../../../api/endpoints/clients";
import { useCart } from "../../../../../../contexts/cartContext";
import DistributorsCodeExpo from "../../../../../common/distributors-code-expo/DistributorsCodeExpo";

const GUTTER = 10;

export default function PharmacyCreateStep({
	laboratoryId,
	pharmacyInfo,
	setPharmacyInfo,
	setStep,
	setClientInfo,
	setSelectedClient,
	marketSellerClient,
}) {
	const [form] = Form.useForm();
	const { setUserId } = useCart();
	const [submitting, setSubmitting] = useState(false);
	const [idProvincia, setIdProvincia] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();
	const [activeIsChain, setActiveIsChain] = useState(false);
	const [afipData, setAfipData] = useState(null);
	const [isCuitValid, setIsCuitValid] = useState(null);
	const [distributors, setDistributors] = useState(null);

	const businessCuit = Form.useWatch("cuit", form);
	const businessName = Form.useWatch("businessName", form);
	const businessAddress = Form.useWatch("address", form);
	const businessPostalCode = Form.useWatch("postalCode", form);

	useEffect(() => {
		setProvincia(
			pharmacyInfo
				? {
						key: pharmacyInfo?.province,
						value: pharmacyInfo?.province,
						label: pharmacyInfo?.provinceName,
				  }
				: null
		);

		setLocalidad(
			pharmacyInfo
				? [
						{
							key: pharmacyInfo.locality,
							value: pharmacyInfo.locality,
							label: pharmacyInfo.localityName,
						},
				  ]
				: undefined
		);
		setIdProvincia(pharmacyInfo ? pharmacyInfo?.province : null);
	}, []);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	useEffect(() => {
		if (businessCuit && businessCuit.length === 11) {
			try {
				if (validateCUIT(businessCuit)) {
					setIsCuitValid(true);
					fetch_afip_check(businessCuit);
				} else {
					setIsCuitValid(false);
				}
			} catch (error) {
				console.log(error);
				setIsCuitValid(false);
				// setCanSubmit(false);
			}
		} else {
			setAfipData(null);
			setIsCuitValid(null);
			// setCanSubmit(false);
			form.setFieldsValue({
				businessName: null,
				address: null,
				postalCode: null,
			});
		}
	}, [businessCuit]);

	const onFinish = async (values) => {
		const continueWithSubmission = async () => {
			try {
				if (!provincia || !localidad) {
					setSubmitting(false);
					openNotificationWithIcon(
						TYPE.ERROR,
						"Para avanzar debe seleccionar una Provincia y una Localidad"
					);
					return;
				}

				const newDistributor = distributors.map((d) =>
					d.checked === true
						? d.code === undefined || d.code.trim() === ""
							? { ...d, code: null }
							: { ...d, code: d.code.trim() }
						: { ...d, code: null }
				);

				if (newDistributor.filter((d) => d.code !== null).length === 0) {
					setSubmitting(false);
					openNotificationWithIcon(
						TYPE.ERROR,
						"Para avanzar debe seleccionar y cargar el código de al menos una droguería"
					);
					return;
				}

				setSubmitting(true);

				const postData = {
					predefinedId: pharmacyInfo.id,
					name: values.clientName,
					clientName: values.clientName,
					businessName: values.businessName,
					address: values.address,
					postalCode: values.postalCode,
					cuit: values.cuit,
					contactName: values.contactName + " " + values.contactLastName,
					contactEmail: values.contactEmail,
					contactPhone: `${values.phoneAreaCode}${values.phone}`,
					pointOfSaleRegion: `ARG-${idProvincia}-${
						localidad?.value ?? pharmacyInfo.locality
					}`,
					localityId: localidad?.value ?? pharmacyInfo.locality,
					createdFrom: "EXPO",
					isChain: activeIsChain,
					distributors: newDistributor.map((d, i) => {
						return {
							id: d.id,
							code: d.code,
						};
					}),
				};

				let response = await create_client_from_predefined_pointOfSale(
					postData
				);

				if (response?.status !== undefined) {
					const errors = Object.entries(response?.errors);
					openNotificationWithIcon(
						TYPE.ERROR,
						errors.map((e) => `${e[1]} `).concat()
					);
				} else {
					openNotificationWithIcon(
						TYPE.SUCCESS,
						"Farmacia creada con éxito",
						"Ya puede generar el pedido para este cliente."
					);
					setSelectedClient(response.clientId);
					setClientInfo({
						...postData,
						phone: postData.contactPhone,
						clientId: response.clientId,
					});
					setUserId(response.userClientId);
					setPharmacyInfo({
						...pharmacyInfo,
						id: response.posId,
						userId: response.userClientId,
					});
					marketSellerClient({ clientId: response.clientId });
					// await send_password_reset_email(postData.contactEmail);
					setStep(4);
				}
			} catch (error) {
				const errors = Object.entries(error?.response?.data?.errors);
				openNotificationWithIcon(
					TYPE.ERROR,
					errors.map((e) => `${e[1]} `).concat()
				);
				if (error.name === "FirebaseError") {
					openNotificationWithIcon(TYPE.ERROR, "Firebase error: " + error.name);
				} else {
				}
			} finally {
				setSubmitting(false);
			}
		};

		try {
			// if (!canSubmit) {
			// 	confirm({
			// 		title: "Advertencia",
			// 		icon: <ExclamationCircleOutlined style={{ color: COLORS.Warning }} />,
			// 		content:
			// 			"No se pudo validar el cuit. Esto puede afectar al registro de la farmacia",
			// 		okText: "Enviar",
			// 		okType: "danger",
			// 		cancelText: "Cancelar",
			// 		onOk: continueWithSubmission,
			// 		onCancel: () => {
			// 			setSubmitting(false);
			// 			return;
			// 		},
			// 	});
			// } else {
			await continueWithSubmission();
			// }
		} catch (error) {
			setSubmitting(false);

			const errors = Object.entries(error?.response?.data?.errors);
			openNotificationWithIcon(
				TYPE.ERROR,
				errors.map((e) => `${e[1]} `).concat()
			);
			if (error.name === "FirebaseError") {
				openNotificationWithIcon(TYPE.ERROR, "Firebase error: " + error.name);
			} else {
			}
		} finally {
			setSubmitting(false);
		}
	};

	const handleProvinciasSelect = (value, object) => {
		setProvincia(object);
		setIdProvincia(value);
	};

	const handleLocalidadSelect = (value, objectList) => {
		setLocalidad(objectList);
	};

	const fetch_afip_check = async (cuit) => {
		const data = await get_cuit_info(cuit);
		setAfipData(data?.cuitInfo);
		if (data?.cuitInfo?.condicionIVA === CONSTANTS.AFIP_STATUS_OK) {
			form.setFieldsValue({
				businessName: data?.cuitInfo?.razonSocial,
				address: data?.cuitInfo?.fiscalDireccion,
				postalCode: data?.cuitInfo?.fiscalCodPostal,
			});
		}
	};

	// const handleCuitCheck = () => {
	// 	setLoading(true);
	// 	setIsCuitValid(null);
	// 	setAfipData(null);
	// 	try {
	// 		if (validateCUIT(businessCuit)) {
	// 			setIsCuitValid(CONSTANTS.CUIT_STRUCTURE_OK);
	// 			fetch_afip_check(businessCuit);
	// 		} else {
	// 			setIsCuitValid(CONSTANTS.CUIT_STRUCTURE_ERROR);
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 		setIsCuitValid(CONSTANTS.CUIT_STRUCTURE_ERROR);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	const handleIsChain = (e) => {
		setActiveIsChain(e.target.checked);
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				window.location.reload();
			},
		});
	};

	return (
		<Section>
			<Form
				form={form}
				name="basic"
				onFinish={onFinish}
				autoComplete="off"
				requiredMark={false}
				layout="vertical"
				initialValues={pharmacyInfo}
			>
				<InnerForm>
					<Typo type="primary" level={4}>
						Crear farmacia
					</Typo>
					{/* <Container> */}
					<Row gutter={GUTTER}>
						<Col span={5}>
							<Form.Item
								label={
									<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
										Nombre Farmacia
									</label>
								}
								name="clientName"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								<Input placeholder="Nombre" />
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										CUIT
									</label>
								}
								name="cuit"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(/^[0-9\s]*$/),
										message: "Solo numeros positivos",
									},
								]}
								help={
									isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
										? "CUIT inválido"
										: afipData
										? afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK
											? ""
											: "No es responsable inscripto"
										: null
								}
								validateStatus={
									isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
										? "error"
										: afipData
										? afipData?.condicionIVA === CONSTANTS.AFIP_STATUS_OK
											? "success"
											: "error"
										: ""
								}
								hasFeedback
							>
								<Input placeholder="Cuit" />
							</Form.Item>
						</Col>
						<Col span={5}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Razon Social
									</label>
								}
								name="businessName"
								help={
									isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
										? "CUIT inválido"
										: afipData
										? afipData?.razonSocial === businessName
											? ""
											: afipData?.condicionIVA !== CONSTANTS.AFIP_STATUS_OK
											? "No es un responsable inscripto"
											: "No coindicide con dato de Afip"
										: null
								}
								validateStatus={
									isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
										? "error"
										: afipData
										? afipData?.razonSocial === businessName
											? "success"
											: "error"
										: ""
								}
								hasFeedback
							>
								<Input placeholder="Razon Social" />
							</Form.Item>
						</Col>
						<Col span={7}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Dirección
									</label>
								}
								name="address"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
								help={
									isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
										? "CUIT inválido"
										: afipData
										? afipData?.fiscalDireccion === businessAddress
											? ""
											: afipData?.condicionIVA !== CONSTANTS.AFIP_STATUS_OK
											? "No es un responsable inscripto"
											: "No coindicide con dato de Afip"
										: null
								}
								validateStatus={
									isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
										? "error"
										: afipData
										? afipData?.fiscalDireccion === businessAddress
											? "success"
											: "error"
										: ""
								}
								hasFeedback
							>
								<Input placeholder="Dirección" />
							</Form.Item>
						</Col>
						<Col span={3}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Código Postal
									</label>
								}
								name="postalCode"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
										message:
											"No se aceptan caracteres especiales. Solo letras y numeros",
									},
								]}
								help={
									isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR ? (
										"CUIT inválido"
									) : afipData ? (
										afipData?.fiscalCodPostal === businessPostalCode ? (
											""
										) : afipData?.condicionIVA !== CONSTANTS.AFIP_STATUS_OK ? (
											<div
												style={{ overflow: "visible", whiteSpace: "nowrap" }}
											>
												No es un responsable inscripto
											</div>
										) : (
											<div
												style={{ overflow: "visible", whiteSpace: "nowrap" }}
											>
												No coindicide con dato de Afip
											</div>
										)
									) : null
								}
								validateStatus={
									isCuitValid === CONSTANTS.CUIT_STRUCTURE_ERROR
										? "error"
										: afipData
										? afipData?.fiscalCodPostal === businessPostalCode
											? "success"
											: "error"
										: ""
								}
								hasFeedback
							>
								<Input placeholder="CP" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={8}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										Provincia
									</label>
								}
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								{localidad !== undefined ? (
									<Select value={provincia} disabled>
										<Select.Option
											key={provincia?.value}
											value={provincia?.value}
										>
											{provincia?.children}
										</Select.Option>
									</Select>
								) : (
									<Select
										onChange={handleProvinciasSelect}
										value={provincia}
										style={{ minWidth: "250px" }}
									>
										{listadoProvincias
											?.sort((a, b) => {
												const titleA = a.name.toLowerCase();
												const titleB = b.name.toLowerCase();
												if (titleA < titleB) {
													return -1;
												}
												if (titleA > titleB) {
													return 1;
												}

												return 0;
											})
											.map((p) => (
												<Select.Option key={p.id} value={p.id}>
													{p.name}
												</Select.Option>
											))}
									</Select>
								)}
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label={
									<label
										style={{
											color: COLORS.Primary,
											fontWeight: "600",
										}}
									>
										{provincia ? "Localidad" : ""}
									</label>
								}
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								{provincia && listadoLocalidades ? (
									<Select
										allowClear={true}
										placeholder="Elegir Localidad"
										onClear={() => setLocalidad(undefined)}
										onChange={handleLocalidadSelect}
										value={localidad}
										style={{ minWidth: "250px" }}
									>
										{idProvincia &&
											listadoLocalidades[0]?.localities
												.sort((a, b) => {
													const titleA = a.locality.title.toLowerCase();
													const titleB = b.locality.title.toLowerCase();
													if (titleA < titleB) {
														return -1;
													}
													if (titleA > titleB) {
														return 1;
													}

													return 0;
												})
												.map((p) => (
													<Select.Option
														key={p.locality.id}
														value={p.locality.id}
													>
														{p.locality.title}
													</Select.Option>
												))}
									</Select>
								) : (
									""
								)}
							</Form.Item>
						</Col>
						<Col
							span={8}
							style={{ display: "flex", alignItems: "end", paddingLeft: 20 }}
						>
							<Form.Item>
								<Checkbox onChange={handleIsChain} checked={activeIsChain}>
									<Typo type="secondary" level={6}>
										Es una cadena con sucursales
									</Typo>
								</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						<Col span={5}>
							<Form.Item
								label={
									<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
										Nombre del representante
									</label>
								}
								name="contactName"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
										message:
											"No se aceptan caracteres especiales. Solo letras y numeros",
									},
								]}
							>
								<Input placeholder="Nombre del representante" />
							</Form.Item>
						</Col>
						<Col span={5}>
							<Form.Item
								label={
									<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
										Apellido del representante
									</label>
								}
								name="contactLastName"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
									{
										pattern: new RegExp(/^(?!\s*$)[a-zA-ZÀ-ÿ0-9\s]*$/),
										message:
											"No se aceptan caracteres especiales. Solo letras y numeros",
									},
								]}
							>
								<Input placeholder="Apellido del representante" />
							</Form.Item>
						</Col>
						<Col span={7}>
							<Form.Item
								label={
									<label style={{ color: COLORS.Primary, fontWeight: "600" }}>
										Correo de Contacto
									</label>
								}
								name="contactEmail"
								rules={[
									{
										required: true,
										message: "Este campo es obligatorio",
									},
								]}
							>
								<Input placeholder="Correo de Contacto" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Container style={{ width: "210px" }}>
								<PhoneInput
									form={form}
									size="default"
									label="Teléfono de Contacto"
									labelStyle={{
										color: COLORS.Primary,
										fontWeight: "600",
									}}
								/>
							</Container>
						</Col>
					</Row>
					<Row gutter={GUTTER}>
						{/* <Col span={24} align="start">
						<Typo level={4} type="primary">
							Droguerías
						</Typo>
					</Col> */}
						<Typo level={5} type="darkmuted">
							Ingrese el código de las droguerías con las cuales trabaja
						</Typo>
						<SignInTemplate.Break height="15px" />
						<Col span={20} align="start">
							<DistributorsCodeExpo onChange={setDistributors} />
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item>
								<AntdButton
									type="primary"
									htmlType="submit"
									loading={submitting}
									width={200}
								>
									Crear farmacia
								</AntdButton>
							</Form.Item>
							<AntdButton
								backGroundColor={COLORS.White}
								loading={submitting}
								onClick={handleBack}
								width={200}
							>
								<Typo type="danger" level={6}>
									Volver sin guardar
								</Typo>
							</AntdButton>
						</Col>
					</Row>
				</InnerForm>
			</Form>
		</Section>
	);
}
