import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { AntdInput, Inner } from "./styles";
import { Button, Input } from "antd";

export default function ProductQuantity({ product, onChange }) {
	const handleDecrement = () => {
		if (product.quantity > product.record.minQuantity)
			onChange({
				index: product.index,
				value: product.record.quantity - 1,
				id: product.record.id,
			});
		if (product.quantity <= product.record.minQuantity)
			onChange({
				index: product.index,
				value: 0,
				id: product.record.id,
			});
	};

	const handleChange = (e) => {
		if (e.target.value < 0) {
			e.target.value = 0;
			e.target.setSelectionRange(0, 0);
			e.target.focus();
			e.target.select();
		}
		if (e.target.value > 0 && e.target.value < product.record.minQuantity) {
			e.target.value = product.record.minQuantity;
			e.target.setSelectionRange(0, 0);
			e.target.focus();
			e.target.select();
		}
		if (e.target.value >= 999) e.target.value = 999;
		const onlyPositiveInteger = /^[0-9\b]+$/;
		if (onlyPositiveInteger.test(e.target.value))
			onChange({
				index: product.index,
				value: e.target.value,
				id: product.record.id,
			});
	};

	const handleIncrement = (e) => {
		if (product.quantity < product.record.minQuantity)
			product.record.quantity = product.record.minQuantity - 1;
		if (product.record.quantity >= 999) product.record.quantity = 998;
		onChange({
			index: product.index,
			value: product.record.quantity + 1,
			id: product.record.id,
		});
	};

	const handleFocus = (event) => event.target.select();

	return (
		<Inner>
			<Button
				size="small"
				shape="circle"
				onClick={handleDecrement}
				disabled={product.quantity <= 0}
			>
				<MinusOutlined />
			</Button>
			<AntdInput
				hasChanges={product.record.hasChanges}
				value={product.quantity}
				onChange={handleChange}
				onFocus={handleFocus}
			/>
			<Button size="small" shape="circle" onClick={handleIncrement}>
				<PlusOutlined />
			</Button>
		</Inner>
	);
}
