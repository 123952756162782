import React, { useState, useEffect, useMemo } from "react";
import { Space, Radio, Dropdown, Menu, Modal, Select } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
	ActionButton,
	AntdButton,
	AntdRadioButton,
	AntdTable,
	Filters,
	Inner,
	RadioOptions,
	TableContainer,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import LoadingHeader from "./components/LoadingHeader";
import { COLORS, Typo } from "../../../../layout/theme";
import { get_users_by_clientId } from "../../../../../api/endpoints/users";
import debounce from "lodash/debounce";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
	checkRolPermission,
	setRolName,
} from "../../../../../utils/checkRolPermission";
import AntdSearch from "../../../../common/antd-search/AntdSearch";
import {
	export_laboratory_routes,
	get_routes,
	get_routes_header_info,
	update_general_route_state,
} from "../../../../../api/endpoints/routes";
import moment from "moment";

const { confirm } = Modal;

export default function RouteList({ clientId, client, userType }) {
	const [loading, setLoading] = useState(false);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [clientGeneralPos, setClientGeneralPos] = useState(null);
	const [users, setUsers] = useState(null);
	const [state, setState] = useState("ACTIVE");
	const [key, setKey] = useState("null");
	const [page, setPage] = useState(1);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre</div>,
			dataIndex: "name",
			render: (value, record) => (
				<Typo
					type={record.hasChanges ? "white" : "secondary"}
					fontWeight={600}
					fontSize={14}
					onMouseOver={() => setKey(record)}
				>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cuit</div>,
			dataIndex: "cuit",
			render: (value, record) => (
				<Typo
					type={record.hasChanges ? "white" : "muted"}
					fontWeight={600}
					fontSize={14}
				>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Dirección</div>,
			dataIndex: "address",
			render: (value, record) => (
				<Typo
					type={record.hasChanges ? "white" : "muted"}
					fontWeight={600}
					fontSize={14}
				>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Provincia</div>,
			dataIndex: "province",
			render: (value, record) => (
				<Typo
					type={record.hasChanges ? "white" : "muted"}
					fontWeight={600}
					fontSize={14}
				>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Localidad</div>,
			dataIndex: "location",
			render: (value, record) => (
				<Typo
					type={record.hasChanges ? "white" : "muted"}
					fontWeight={600}
					fontSize={14}
				>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Vendedor</div>,
			dataIndex: "userId",
			width: 250,
			render: (value, record, index) => (
				<div
					style={{
						color: record.hasChanges ? COLORS.White : COLORS.Grey,

						fontWeight: "600",
					}}
				>
					<Select
						disabled
						value={users?.find((u) => u.id === value)?.firstName}
						style={{ width: "100%" }}
					></Select>
				</div>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			width: 30,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={state === "ACTIVE" ? menuActive : menuDeleted}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menuActive = (
		<Menu
			items={[
				{
					key: "2",
					label: <Typo onClick={() => deleteRoute(key?.id)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const menuDeleted = (
		<Menu
			items={[
				{
					key: "2",
					label: <Typo onClick={() => activateRoute(key?.id)}>Activar</Typo>,
				},
			]}
		/>
	);

	const fetch_users = async (search) => {
		const res = await get_users_by_clientId(search, clientId, userType);
		setUsers(
			res
				?.map((r) => ({
					...r,
					rol: setRolName(checkRolPermission(r, client)),
				}))
				.filter((c) => !c.deleted)
		);
	};

	const fetch_routes = async (search, state, clientId) => {
		setLoading(true);
		try {
			const res = await get_routes(search, clientId, state);
			setClientGeneralPos(
				res.map((r) => ({
					...r,
					key: r.id,
					name: r.preDefinedPointsOfSale?.name,
					cuit: r.preDefinedPointsOfSale?.cuit,
					address: r.preDefinedPointsOfSale?.address,
					province: r.preDefinedPointsOfSale?.province,
					location: r.preDefinedPointsOfSale?.location,
					hasChanges: false,
				}))
			);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const fetch_routes_header = async () => {
		const res = await get_routes_header_info(clientId);
		setHeaderInfo(res);
	};

	useEffect(() => {
		fetch_users("");
		fetch_routes("", state, clientId);
		fetch_routes_header();
		setLoading(false);
	}, [state, clientId]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_routes(e.target.value, state, clientId);
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 600),
		[clientId]
	);

	const deleteRoute = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar esta ruta?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La ruta se quitará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "DELETED");
			},
		});
	};

	const activateRoute = (key) => {
		confirm({
			title: "¿Seguro que quiere activar esta ruta?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La ruta se activara de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "ACTIVE");
			},
		});
	};

	const handleAction = async (key, state) => {
		try {
			const _state = { id: key, state: state };
			await update_general_route_state(_state);
			fetch_users("");
			fetch_routes("", state, clientId);
			fetch_routes_header();
		} catch (err) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"La ruta no se puede borrar",
				"La ruta no se puede borrar"
			);
		}
	};

	const handleRadioCheck = (e) => {
		setState(e.target.value);
	};

	const fetch_export_routes_to_assign = async () => {
		setLoading(true);
		try {
			const res = await export_laboratory_routes(clientId);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName =
				"PuntosDeVenta_" +
				client.name +
				"_" +
				moment().format("DD-MM-YYYY") +
				".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Inner>
			<Filters>
				{!headerInfo ? (
					<LoadingHeader />
				) : (
					<Space
						size="large"
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<AntdSearch
							width={400}
							allowClear
							placeholder="Buscar punto de venta"
							onChange={debouncedEventHandler}
						/>
						<AntdButton
							onClick={fetch_export_routes_to_assign}
							loading={loading}
						>
							Exportar Datos
						</AntdButton>
					</Space>
				)}
			</Filters>
			<RadioOptions>
				<div>
					<Radio.Group value={state} onChange={handleRadioCheck}>
						<AntdRadioButton value="ACTIVE">
							Activo ({headerInfo?.totalActive})
						</AntdRadioButton>
						<AntdRadioButton value="DELETED">
							Inactivo ({headerInfo?.totalDisabled})
						</AntdRadioButton>
					</Radio.Group>
				</div>
				<Typo type="primary" level={6}>
					Total PDV asociados:{" "}
					{headerInfo?.totalActive + headerInfo?.totalDisabled}
				</Typo>
			</RadioOptions>
			<TableContainer>
				<AntdTable
					dataSource={clientGeneralPos}
					columns={columns}
					loading={loading}
					pagination={{
						pageSize: 100,
						showSizeChanger: false,
						onChange: (page) => setPage(page),
					}}
					// scroll={{
					// 	y: 450,
					// }}
					rowClassName={(record) => (record.hasChanges ? "hasChanges" : null)}
				/>
			</TableContainer>
		</Inner>
	);
}
