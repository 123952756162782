import React from "react";
import { ModalContainer } from "./styles";
import ProductDetail from "../product-detail/ProductDetail";

export default function ProductModal({ id, minQuantity, maxQuantity }) {
	return (
		<ModalContainer>
			<ProductDetail
				id={id}
				minQuantity={minQuantity}
				maxQuantity={maxQuantity}
			></ProductDetail>
		</ModalContainer>
	);
}
