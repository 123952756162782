import React, { useEffect, useRef, useState } from "react";
import {
	Inner,
	Container,
	LeftContainer,
	LeftCarousel,
	ImageContainer,
	MainImageContainer,
	ImageFooter,
	Info,
	InfoRow,
	RightContainer,
	Line,
	PriceTextOff,
	PriceTextOffContainer,
	InfoSection,
	InfoDescription,
	ContainerShadow,
	InfoIva,
	BottomContainer,
	Section,
	SubSection,
	SectionItems,
	SectionOffer,
	TriggerQtyContainer,
	SpecialOfferContainer,
	OffContainer,
	MinQtyContainer,
} from "./styles";
import { Card, Carousel } from "antd";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import { get_products_by_id } from "../../../../../../api/endpoints/products";
import { Typo } from "../../../../../layout/theme";
import Image from "../../../../../common/image/Image";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { compareWithToday } from "../../../../../../utils/compareDates";

export default function ProductDetail({ id, minQuantity = 1 }) {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const mediaRef = useRef(null);
	const navRef = useRef(null);

	const onChange = (currentSlide) => {
		mediaRef.current.goTo(currentSlide, false);
	};

	useEffect(() => {
		const fetch_product_by_id = async () => {
			setLoading(true);
			const res = await get_products_by_id(id);

			setData(res);
			setLoading(false);
		};
		fetch_product_by_id();
	}, []);

	if (!data) return <LoadingSkeleton />;

	return (
		<Inner>
			<Card style={{ position: "static" }}>
				<Container>
					<LeftContainer>
						<InfoSection>
							<Typo type="secondary">EAN: </Typo>
							<Info>
								<Typo level={7} type="muted">
									{`${data.ean13}`}
								</Typo>
							</Info>
						</InfoSection>
						<LeftCarousel>
							{data && data.photoGallery.length > 0 && (
								<div>
									<Carousel
										asNavFor={navRef.current}
										touchMove={false}
										dots={false}
										ref={mediaRef}
										effect="fade"
									>
										<MainImageContainer>
											<Image
												style={{
													paddingTop: "90px",
													height: "400px",
													width: "100%",
												}}
												src={data.photoUrl}
											/>
										</MainImageContainer>
										{data &&
											data.photoGallery.map((i) => (
												<ImageFooter key={i.id}>
													<Image
														style={{
															paddingTop: "90px",
															height: "400px",
															width: "100%",
														}}
														src={i.url}
													/>
												</ImageFooter>
											))}
									</Carousel>
									<ContainerShadow
										style={{
											boxShadow:
												"rgb(50 50 93 / 25%) 40px 30px 20px -40px, rgb(0 0 0 / 25%) 0px 20px 30px -20px",
										}}
									>
										<Carousel
											slidesToShow={2}
											centerMode={true}
											asNavFor={mediaRef.current}
											draggable={true}
											ref={(carousel) => (navRef.current = carousel)}
											afterChange={onChange}
											swipeToSlide={true}
											touchThreshold={50}
											focusOnSelect={true}
											dots={false}
											style={{ marginTop: 10 }}
										>
											<ImageContainer>
												<Image
													style={{
														height: "90px",
														width: "80%",
														objectFit: "scale-down",
													}}
													src={data.photoUrl}
												/>
											</ImageContainer>
											{data &&
												data.photoGallery.map((i) => (
													<ImageContainer key={i.id}>
														<Image
															style={{
																height: "90px",
																width: "80%",
																objectFit: "scale-down",
															}}
															src={i.url}
														/>
													</ImageContainer>
												))}
										</Carousel>
									</ContainerShadow>
								</div>
							)}
							{data && data.photoGallery.length === 0 && (
								<ImageContainer>
									<Image
										preview={true}
										style={{
											height: "400px",
											width: "100%",
											objectFit: "scale-down",
										}}
										src={data.photoUrl}
									/>
								</ImageContainer>
							)}
						</LeftCarousel>
						<SectionOffer>
							{data.triggerQty > 0 ? (
								<TriggerQtyContainer>
									Comprando {data.triggerQty} o más
								</TriggerQtyContainer>
							) : minQuantity > 1 ? (
								<MinQtyContainer>
									Compra mínima {minQuantity} un.
								</MinQtyContainer>
							) : (
								` `
							)}
							{data.triggerQty > 0 && (
								<SpecialOfferContainer>
									<OffContainer translate="no">OFF</OffContainer>
									<Typo level={5} type="green">
										{`${numberWithDotAndCommas(
											(
												100 -
												((data.offerPrice -
													(data.offerPrice * data.triggerDiscount) / 100) *
													100) /
													data.price
											).toFixed(2)
										)}%`}
									</Typo>
								</SpecialOfferContainer>
							)}
						</SectionOffer>
					</LeftContainer>
					<RightContainer>
						{data && (
							<Info>
								{data.offerPrice > 0 && data.offerPrice !== data.price ? (
									<InfoRow>
										<Section>
											{data?.discount > 0 &&
												data?.offerFrom &&
												data?.offerTo &&
												compareWithToday(data?.offerTo) && (
													<PriceTextOffContainer>
														<PriceTextOff>PSL</PriceTextOff>
														<Typo
															level={4}
															type="success"
															style={{ paddingLeft: "10px" }}
														>
															-
															{`${numberWithDotAndCommas(
																data.discount.toFixed(2)
															)}%`}
														</Typo>
													</PriceTextOffContainer>
												)}
											{data.client.typeOfSale === "PVP" && (
												<PriceTextOffContainer>
													<PriceTextOff translate="no">PVP</PriceTextOff>
													<Typo
														level={4}
														type="secondary"
														style={{ paddingLeft: "10px" }}
													>
														-
														{`${numberWithDotAndCommas(
															(
																100 -
																(data.offerPrice * 100) / data.price
															).toFixed(2)
														)}%`}
													</Typo>
												</PriceTextOffContainer>
											)}
										</Section>
										<Section>
											<SubSection>
												<Typo type="muted" level={7}>
													{data.client.typeOfSale}
												</Typo>
												<Line>
													<Typo level={3} type="mediumGrey" underline>
														${numberWithDotAndCommas(data.price)}
													</Typo>
												</Line>
											</SubSection>
										</Section>
										<InfoIva>IVA no incluido</InfoIva>
									</InfoRow>
								) : (
									<InfoRow>
										<SubSection>
											<Typo type="primary" level={7}>
												{data.client.typeOfSale}
											</Typo>
											<Typo level={1} type="secondary" underline>
												${numberWithDotAndCommas(data.price)}
											</Typo>
										</SubSection>
									</InfoRow>
								)}
								<Typo level={4} type="secondary">
									{data.title}
								</Typo>
								<Typo type="muted" level={5}>
									{data.shortDescription}
								</Typo>
								<Section style={{ justifyContent: "space-between" }}>
									<SectionItems>
										<Typo type="secondary">Categorias: </Typo>
										<Typo level={7} type="muted">
											{`${data.categories.map((c) => c.name).join(", ")}`}
										</Typo>
									</SectionItems>
									<SectionItems>
										<Typo type="secondary">Principios Activos: </Typo>
										<Typo level={7} type="muted">
											{data?.activeIngredients?.length > 1
												? `${data.activeIngredients
														.map((a) => a.name)
														.join(", ")}`
												: data?.activeIngredients[0]?.name === "No aplica"
												? ""
												: data?.activeIngredients[0]?.name}
										</Typo>
									</SectionItems>
								</Section>
								<SectionItems>
									<Typo type="secondary">Presentación: </Typo>
									<Typo level={7} type="muted">
										{`${data.presentation?.name}.`}
									</Typo>
								</SectionItems>
							</Info>
						)}
						<div>
							{data.client.typeOfSale === "PVP" && (
								<Typo type="muted" level={8} translate="no">
									* PVP: Precio Venta Público
								</Typo>
							)}
							{data.client.typeOfSale === "PSD" && (
								<Typo type="muted" level={8} translate="no">
									* PSD: Precio Sugerido Droguería
								</Typo>
							)}
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "end",
									alignItems: "center",
									marginTop: 10,
								}}
							>
								<Typo level={8} type="terciary" translate="no">
									Los precios PVP, PSD y PSL son de referencia y pueden variar
								</Typo>
								<Typo level={8} type="terciary">
									dependiendo de la droguería seleccionada.
								</Typo>
							</div>
						</div>
					</RightContainer>
				</Container>

				<BottomContainer>
					{data.longDescription && (
						<InfoDescription>
							<Typo level={6} type="secondary">
								Descripción:
							</Typo>
							<Info style={{ marginTop: 5 }}>
								<Typo level={6} type="muted">
									{`${data.longDescription}`}
								</Typo>
							</Info>
						</InfoDescription>
					)}
				</BottomContainer>
			</Card>
		</Inner>
	);
}
