import { Spin, Statistic, Table } from "antd";
import React from "react";
import {
	AntdCard,
	Container,
	IconContainer,
	IconGroupContainer,
	TableContainer,
} from "./styles";
import { COLORS, SIZES, Typo, WEIGHT } from "../../../../../layout/theme";
import Chart from "../../../../../common/chart/Chart";

export default function Box({
	data,
	subData,
	secondData,
	secondSubData,
	monthName,
	graphData,
	title,
	subTitle,
	prefix,
	suffix,
	ganancia,
	type,
	options,
	iconOption,
	option2,
	iconOption2,
	profit,
	units,
	columns,
	noName,
	isLoading,
	dataPrefix,
	dataSuffix,
	secondDataSuffix,
	distributorGraph,
	selectDistributorGraph,
	selectOrdersInfo,
	ordersInfo,
	secondGraphData,
	selectDistributorsInfo,
	distributorsInfo,
	options2,
	thirdGraphData,
	iconOption3,
	productGraph,
	selectProductGraph,
	option3,
}) {
	if (isLoading)
		return (
			<AntdCard>
				<Spin />
			</AntdCard>
		);

	return type === "ganancia" ? (
		<AntdCard>
			<Typo level={2} type="primary">
				{prefix}
			</Typo>
			<Statistic
				title={title}
				value={data}
				valueStyle={{
					fontSize: "38px",
					fontWeight: "700",
					color: COLORS.Primary,
				}}
			/>
		</AntdCard>
	) : type === "chart" ? (
		<AntdCard>
			<Typo level={2} type="primary">
				{prefix}
			</Typo>
			<div>
				<Typo type="secondary">{title}</Typo>
			</div>
			<Chart options={options} />
		</AntdCard>
	) : type === "top5" ? (
		<AntdCard>
			<Typo level={2} type="secondary">
				{prefix}
			</Typo>
			<div>{title}</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "start",
					height: "100%",
				}}
			>
				{data.map((e) => (
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							marginTop: "10px",
						}}
					>
						{noName ? null : (
							<Typo type="primary" style={{ marginLeft: "10px" }}>
								{e.name.length > 18 ? e.name.slice(0, 16) + "..." : e.name}
							</Typo>
						)}
						<Typo type="primary" level={6}>
							{profit && `$`}
							{e.total.toLocaleString(undefined)}
						</Typo>
					</div>
				))}
			</div>
		</AntdCard>
	) : type === "anualChart" ? (
		<AntdCard>
			{title}
			<Chart options={options} big />
		</AntdCard>
	) : type === "bigTop" ? (
		<AntdCard>
			<Typo level={3} type="secondary">
				{title}
			</Typo>
			<TableContainer>
				<Table
					dataSource={data}
					columns={columns}
					style={{ marginTop: "20px" }}
					pagination={false}
				/>
			</TableContainer>
		</AntdCard>
	) : type === "headerBox" ? (
		<AntdCard>
			<Container
				style={{ alignItems: "center", justifyContent: "center", height: 40 }}
			>
				<Typo level={2} type="primary">
					{title}
				</Typo>
				{/* <Typo level={2} type="primary">
					{prefix}
				</Typo> */}
			</Container>
			<Container>
				<Statistic
					prefix={dataPrefix}
					value={data}
					valueStyle={{
						fontSize: "38px",
						fontWeight: "700",
						color: COLORS.Secondary,
					}}
				/>
				{subData && (
					<Container>
						<Typo type="secondary" level={5}>
							~{" "}
						</Typo>
						<Statistic
							value={subData}
							prefix={dataPrefix}
							valueStyle={{
								fontSize: SIZES.level3,
								fontWeight: WEIGHT.Black,
								color: COLORS.Default,
							}}
						/>
						<Typo type="terciary" level={6}>
							{monthName}
						</Typo>
					</Container>
				)}
				{secondData && (
					<Statistic
						value={secondData}
						valueStyle={{
							fontSize: "38px",
							fontWeight: "700",
							color: COLORS.Secondary,
						}}
					/>
				)}
				{secondSubData && (
					<Container>
						<Typo type="secondary" level={5}>
							~{" "}
						</Typo>
						<Statistic
							value={secondSubData}
							suffix={dataSuffix}
							valueStyle={{
								fontSize: SIZES.level3,
								fontWeight: WEIGHT.Black,
								color: COLORS.Default,
							}}
						/>
						<Typo type="terciary" level={6}>
							{monthName}
						</Typo>
					</Container>
				)}
			</Container>
			{graphData && (
				<Container>
					<Chart options={graphData} headerBox />
				</Container>
			)}
		</AntdCard>
	) : type === "headerBoxMobile" ? (
		<AntdCard style={{ height: 230 }}>
			{title && (
				<Container
					style={{ alignItems: "center", justifyContent: "center", height: 40 }}
				>
					<Typo level={2} type="primary">
						{title}
					</Typo>
				</Container>
			)}
			{data && (
				<Container
					style={{
						paddingTop: 10,
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Statistic
						value={data}
						prefix={dataPrefix}
						suffix={
							<span
								style={{
									fontSize: "26px",
									fontWeight: "700",
									padding: "5px",
									color: COLORS.Secondary,
								}}
							>
								{dataSuffix}
							</span>
						}
						valueStyle={{
							fontSize: "38px",
							fontWeight: "700",
							color: COLORS.Secondary,
						}}
					/>
				</Container>
			)}
			{secondData && (
				<Container
					style={{
						paddingTop: 10,
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Statistic
						value={secondData}
						suffix={
							<span
								style={{
									fontSize: "26px",
									fontWeight: "700",
									padding: "5px",
									color: COLORS.Secondary,
								}}
							>
								{secondDataSuffix}
							</span>
						}
						valueStyle={{
							fontSize: "38px",
							fontWeight: "700",
							color: COLORS.Secondary,
						}}
					/>
				</Container>
			)}
			{graphData && (
				<Container>
					<Chart options={graphData} headerBoxMobile />
				</Container>
			)}
		</AntdCard>
	) : type === "headerBoxSales" ? (
		<AntdCard style={{ justifyContent: "center" }}>
			{title && (
				<Container
					style={{ alignItems: "center", justifyContent: "center", height: 40 }}
				>
					<Typo level={2} type="primary">
						{title}
					</Typo>
					<IconGroupContainer
						style={{ position: "absolute", right: 0, paddingRight: "15px" }}
					>
						{iconOption && data && ordersInfo && (
							<IconContainer
								onClick={() => selectOrdersInfo("SALES")}
								height={30}
								width={30}
								$active={ordersInfo === "SALES"}
							>
								{iconOption}
							</IconContainer>
						)}
						{iconOption2 && data && ordersInfo && (
							<IconContainer
								onClick={() => selectOrdersInfo("UNITS")}
								height={30}
								width={30}
								$active={ordersInfo === "UNITS"}
							>
								{iconOption2}
							</IconContainer>
						)}
					</IconGroupContainer>
				</Container>
			)}
			<Container>
				<Statistic
					prefix={dataPrefix}
					value={data}
					valueStyle={{
						fontSize: "38px",
						fontWeight: "700",
						color: COLORS.Secondary,
					}}
				/>
				{subData && (
					<Container>
						<Typo type="secondary" level={5}>
							~{" "}
						</Typo>
						<Statistic
							value={subData}
							prefix={dataPrefix}
							valueStyle={{
								fontSize: SIZES.level3,
								fontWeight: WEIGHT.Black,
								color: COLORS.Default,
							}}
						/>
						<Typo type="terciary" level={6}>
							{monthName}
						</Typo>
					</Container>
				)}
			</Container>
			{graphData && ordersInfo === "SALES" && (
				<Container>
					<Chart options={graphData} headerBox />
				</Container>
			)}
			{graphData && ordersInfo === "UNITS" && (
				<Container>
					<Chart options={secondGraphData} headerBox />
				</Container>
			)}
		</AntdCard>
	) : type === "headerBoxVSSales" ? (
		<AntdCard style={{ justifyContent: "center" }}>
			{title && (
				<Container
					style={{
						alignItems: "center",
						justifyContent: "space-between",
						height: 40,
						padding: "0px 10px",
					}}
				>
					<Typo level={2} type="primary">
						{title}
					</Typo>
					<IconGroupContainer
						style={{ position: "absolute", right: 0, paddingRight: "15px" }}
					>
						{iconOption && ordersInfo && (
							<IconContainer
								onClick={() => selectOrdersInfo("SALES")}
								height={30}
								width={30}
								$active={ordersInfo === "SALES"}
							>
								{iconOption}
							</IconContainer>
						)}
						{iconOption2 && ordersInfo && (
							<IconContainer
								onClick={() => selectOrdersInfo("UNITS")}
								height={30}
								width={30}
								$active={ordersInfo === "UNITS"}
							>
								{iconOption2}
							</IconContainer>
						)}
						{iconOption3 && ordersInfo && (
							<IconContainer
								onClick={() => selectOrdersInfo("ORDERS")}
								height={30}
								width={30}
								$active={ordersInfo === "ORDERS"}
							>
								{iconOption3}
							</IconContainer>
						)}
					</IconGroupContainer>
				</Container>
			)}
			<Container style={{ minHeight: 55 }}></Container>
			{graphData && ordersInfo === "SALES" && (
				<Container>
					<Chart options={graphData} headerBox />
				</Container>
			)}
			{graphData && ordersInfo === "UNITS" && (
				<Container>
					<Chart options={secondGraphData} headerBox />
				</Container>
			)}
			{graphData && ordersInfo === "ORDERS" && (
				<Container>
					<Chart options={thirdGraphData} headerBox />
				</Container>
			)}
		</AntdCard>
	) : type === "pieHeaderBox" ? (
		<AntdCard>
			<Container
				style={{ alignItems: "center", justifyContent: "center", height: 40 }}
			>
				<Typo level={2} type="primary">
					{title}
				</Typo>
				{/* <Typo level={2} type="primary">
					{prefix}
				</Typo> */}
			</Container>
			<Container>
				<Chart options={options} headerBox />
			</Container>
		</AntdCard>
	) : type === "pieHeaderBoxSales" ? (
		<AntdCard>
			{title && (
				<Container
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						height: 40,
					}}
				>
					<Typo level={2} type="primary">
						{title}
					</Typo>
					<IconGroupContainer>
						{iconOption && options && distributorsInfo && (
							<IconContainer
								onClick={() => selectDistributorsInfo("UNITS")}
								height={30}
								width={30}
								$active={distributorsInfo === "UNITS"}
							>
								{iconOption2}
							</IconContainer>
						)}
						{iconOption2 && options2 && distributorsInfo && (
							<IconContainer
								onClick={() => selectDistributorsInfo("SALES")}
								height={30}
								width={30}
								$active={distributorsInfo === "SALES"}
							>
								{iconOption}
							</IconContainer>
						)}
					</IconGroupContainer>
				</Container>
			)}
			{options && distributorsInfo === "UNITS" && (
				<Container>
					<Chart options={options} headerBoxPie />
				</Container>
			)}
			{options2 && distributorsInfo === "SALES" && (
				<Container>
					<Chart options={options2} headerBoxPie />
				</Container>
			)}
		</AntdCard>
	) : type === "timeChart" ? (
		<AntdCard>
			<Container
				style={{ alignItems: "center", justifyContent: "center", height: 40 }}
			>
				{title}
			</Container>
			{subData && (
				<Container
					style={{ alignItems: "center", justifyContent: "center", height: 40 }}
				>
					<Typo level={3} type="muted">
						{subData} un.
					</Typo>
				</Container>
			)}
			<Container>
				<Chart options={options} timeChart />
			</Container>
		</AntdCard>
	) : type === "anualRevenue" ? (
		<AntdCard>
			<Container
				style={{
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Typo level={4} type="primary">
					{title}
				</Typo>
			</Container>
			<Container
				style={{
					height: "120px",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{data && (
					<Statistic
						value={data}
						prefix={
							<Typo level={2} type="primary">
								{prefix}
							</Typo>
						}
						valueStyle={{
							fontSize: "42px",
							fontWeight: WEIGHT.Black,
							color: COLORS.Primary,
						}}
					/>
				)}
			</Container>
			<Container
				style={{
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Typo level={4} type="mediumGrey">
					{subTitle}
				</Typo>
			</Container>
			<Container
				style={{
					height: "100px",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Statistic
					value={subData}
					suffix={
						<Typo level={3} type="mediumGrey">
							{suffix}
						</Typo>
					}
					valueStyle={{
						fontSize: SIZES.level1,
						fontWeight: WEIGHT.Black,
						color: COLORS.MediumGrey,
					}}
				/>
			</Container>
		</AntdCard>
	) : type === "timeChartMultiple" ? (
		<AntdCard>
			<IconGroupContainer>
				{iconOption && (
					<IconContainer
						onClick={() => selectDistributorGraph("SALES")}
						height={30}
						width={30}
						$active={distributorGraph === "SALES"}
					>
						{iconOption}
					</IconContainer>
				)}
				{iconOption2 && (
					<IconContainer
						onClick={() => selectDistributorGraph("ORDERS")}
						height={30}
						width={30}
						$active={distributorGraph === "ORDERS"}
					>
						{iconOption2}
					</IconContainer>
				)}
			</IconGroupContainer>
			<Container
				style={{ alignItems: "center", justifyContent: "center", height: 40 }}
			>
				{title}
			</Container>
			{subData && (
				<Container
					style={{ alignItems: "center", justifyContent: "center", height: 40 }}
				>
					<Typo level={3} type="muted">
						{subData} un.
					</Typo>
				</Container>
			)}
			{options && distributorGraph === "SALES" && (
				<Container>
					<Chart options={options} timeChart />
				</Container>
			)}
			{option2 && distributorGraph === "ORDERS" && (
				<Container>
					<Chart options={option2} timeChart />
				</Container>
			)}
		</AntdCard>
	) : type === "timeChartSalesUnitsOrders" ? (
		<AntdCard>
			<IconGroupContainer>
				{iconOption && (
					<IconContainer
						onClick={() => selectDistributorGraph("SALES")}
						height={30}
						width={30}
						$active={distributorGraph === "SALES"}
					>
						{iconOption}
					</IconContainer>
				)}
				{iconOption2 && (
					<IconContainer
						onClick={() => selectDistributorGraph("UNITS")}
						height={30}
						width={30}
						$active={distributorGraph === "UNITS"}
					>
						{iconOption2}
					</IconContainer>
				)}
				{iconOption3 && (
					<IconContainer
						onClick={() => selectDistributorGraph("ORDERS")}
						height={30}
						width={30}
						$active={distributorGraph === "ORDERS"}
					>
						{iconOption3}
					</IconContainer>
				)}
			</IconGroupContainer>
			<Container
				style={{ alignItems: "center", justifyContent: "center", height: 40 }}
			>
				{title}
			</Container>
			{subData && (
				<Container
					style={{ alignItems: "center", justifyContent: "center", height: 40 }}
				>
					<Typo level={3} type="muted">
						{subData} un.
					</Typo>
				</Container>
			)}
			{options && distributorGraph === "SALES" && (
				<Container>
					<Chart options={options} timeChart />
				</Container>
			)}
			{option2 && distributorGraph === "UNITS" && (
				<Container>
					<Chart options={option2} timeChart />
				</Container>
			)}
			{option3 && distributorGraph === "ORDERS" && (
				<Container>
					<Chart options={option3} timeChart />
				</Container>
			)}
		</AntdCard>
	) : type === "timeChartMultipleProduct" ? (
		<AntdCard>
			<IconGroupContainer>
				{iconOption && (
					<IconContainer
						onClick={() => selectProductGraph("SALES")}
						height={30}
						width={30}
						$active={productGraph === "SALES"}
					>
						{iconOption}
					</IconContainer>
				)}
				{iconOption2 && (
					<IconContainer
						onClick={() => selectProductGraph("UNITS")}
						height={30}
						width={30}
						$active={productGraph === "UNITS"}
					>
						{iconOption2}
					</IconContainer>
				)}
			</IconGroupContainer>
			<Container
				style={{ alignItems: "center", justifyContent: "center", height: 40 }}
			>
				{title}
			</Container>
			{subData && (
				<Container
					style={{ alignItems: "center", justifyContent: "center", height: 40 }}
				>
					<Typo level={3} type="muted">
						{subData} un.
					</Typo>
				</Container>
			)}
			{options && productGraph === "SALES" && (
				<Container>
					<Chart options={options} timeChart />
				</Container>
			)}
			{option2 && productGraph === "UNITS" && (
				<Container>
					<Chart options={option2} timeChart />
				</Container>
			)}
		</AntdCard>
	) : (
		<p></p>
	);
}
