import axiosInstance from "../axiosInstance";

const CONTROLLER = "sales";

const get_sales = async (filterFrom, filterTo, orderState) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?filterFrom=${filterFrom}&filterTo=${filterTo}&orderState=${orderState}`
	);
	return response.data;
};

const get_sale_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetById/${id}`);
	return response.data;
};

const get_sale_by_client = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetByClient/${id}`);
	return response.data;
};

const get_sale_by_id_with_status = async (id, idClient) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetSaleByIdWithStatus`,
		{
			params: {
				orderId: id,
				clientId: idClient,
			},
		}
	);
	return response.data;
};

const get_laboratory_sales = async (
	laboratoryId,
	distributorId,
	sellerId,
	provinceId,
	dateToSearch,
	typeOfSale,
	orderState,
	skip,
	page
) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetLaboratorySales`, {
		params: {
			laboratoryId: laboratoryId,
			distributorId: distributorId,
			sellerId: sellerId,
			provinceId: provinceId,
			dateToSearch: dateToSearch,
			typeOfSale: typeOfSale,
			orderState: orderState,
			skip: skip,
			page: page,
		},
	});
	return response.data;
};

const export_laboratory_sales = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/ExportLaboratorySales`,
		data,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const get_order_statuses_CT = async (
	laboratoryId,
	distributorId,
	sellerId,
	provinceId,
	dateToSearch,
	typeOfSale,
	orderState,
	skip,
	page
) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetOrderStatusesCT`, {
		params: {
			laboratoryId: laboratoryId,
			distributorId: distributorId,
			sellerId: sellerId,
			provinceId: provinceId,
			dateToSearch: dateToSearch,
			typeOfSale: typeOfSale,
			orderState: orderState,
			skip: skip,
			page: page,
		},
	});
	return response.data;
};

export {
	get_sales,
	get_sale_by_id,
	get_sale_by_client,
	get_sale_by_id_with_status,
	get_laboratory_sales,
	export_laboratory_sales,
	get_order_statuses_CT,
};
