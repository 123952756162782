export const COLORS = {
	Primary: "#fd7e00",
	Secondary: "#004772",
	Default: "#60ba00",
	Grey: "#BBC4C2",
	LightGrey: "#F3F4F6",
	LightGrey2: "#E8EAED",
	MediumGrey: "#99a19f",
	DarkGrey: "#4f4f4f",
	White: "#ffffff",
	Black: "#000000",
	NearBlack: "#171717",
	Danger: "#ff4d4f",
	Warning: "#faad14",
	Success: "#52c41a",
	LightGreen: "#86EFAC",
	StrongGreen: "#4ADE80",
	SkeletonGrey: "#bebebe33",
	LightDefault: "#ebfbff",
	Brown: "#ad7402",
	Purple: "#df40f7",
	Violet: "#88649c",
	LightSuccess: "#dcfae4",
	LightSecondary: "#d4f1fc",
	ChartColors: [
		"#0A4997",
		"#00A4E3",
		"#52c41a",
		"#4ADE80",
		"#ff4d4f",
		"#ad7402",
		"#faad14",
		"#88649c",
		"#df40f7",
		"#BBC4C2",
	],
};

export default COLORS;
