import React, { useState } from "react";
import { Form, Input } from "antd";
import MarketLogo from "../../../../assets/images/logo.png";
import Image from "../../../common/image/Image";
import { Body, Container, InnerForm, Main, Section } from "./styles";
import { useAuth } from "../../../../contexts/authContext";
import SignInTemplate from "../../../layout/sign-in-template/SignInTemplate";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import { Typo } from "../../../layout/theme";

export default function SignIn({
	newUserData = null,
	deletedUser = false,
	blockedUser = false,
}) {
	const { login, logout } = useAuth();
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			if (deletedUser) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Su usuario ha sido inhabilitado",
					"Por favor espere a ser habilitado para el ingreso"
				);
				setSubmitting(false);
				return false;
			}

			if (blockedUser) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"No se permite el login del usuario",
					"Por favor revise sus permisos y credenciales."
				);
				setSubmitting(false);
				return false;
			}

			if (newUserData) {
				openNotificationWithIcon(
					TYPE.ERROR,
					"Su solicitud sigue en revisión",
					"Por favor espere a ser validado para el ingreso. Este proceso puede demorar 48hs."
				);
				setSubmitting(false);
				return false;
			}

			if (!values.email || !values.password) return false;
			await login(values.email, values.password);
		} catch (error) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"No se permite el login del usuario",
				"Por favor revise sus credenciales"
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleInputMailfocus = async () => {
		if (newUserData || deletedUser || blockedUser) {
			localStorage.clear();
			await logout();
		}
	};

	return (
		<SignInTemplate columns="1">
			<Main>
				<SignInTemplate.Logo>
					<Image
						src={MarketLogo}
						alt="Logo FarmaTransfer"
						height="45px"
						width="220px"
					/>
				</SignInTemplate.Logo>
				<SignInTemplate.Top>
					<SignInTemplate.Title>Iniciar Sesión</SignInTemplate.Title>
					<SignInTemplate.Desc>
						Coloque su Correo Electrónico y su Contraseña para ingresar a
						Checkpos Transfer.
					</SignInTemplate.Desc>
				</SignInTemplate.Top>
				<Body>
					<Form
						form={form}
						name="sign-in-form"
						onFinish={onFinish}
						requiredMark={false}
						layout="vertical"
					>
						<InnerForm>
							{newUserData && (
								<>
									<Typo
										type="danger"
										level={6}
										style={{
											padding: "0px 155px 0px 155px",
										}}
									>
										Solicitud pendiente de aprobación, por favor espere a ser
										validado. <br />
										Recuerde que este proceso puede demorar 48hs.
									</Typo>
									<br />
								</>
							)}
							{deletedUser && (
								<>
									<Typo
										type="danger"
										level={6}
										style={{
											padding: "0px 155px 0px 155px",
										}}
									>
										Su usuario ha sido deshabilitado para operar en el sitio
									</Typo>
									<br />
								</>
							)}
							{blockedUser && (
								<>
									<Typo
										type="danger"
										level={6}
										style={{
											padding: "0px 155px 0px 155px",
										}}
									>
										Error al intentar ingresar en el sitio
									</Typo>
									<br />
								</>
							)}
							<Section
								style={{
									flexDirection: "column",
									padding: "0px 155px 0px 155px",
								}}
							>
								<Form.Item
									name="email"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									<Input
										placeholder="@Mail"
										type="email"
										size="large"
										onFocus={handleInputMailfocus}
									/>
								</Form.Item>
								<Form.Item
									name="password"
									rules={[
										{
											required: true,
											message: "Este campo es obligatorio",
										},
									]}
								>
									<Input
										size="large"
										type="password"
										placeholder="Contraseña"
									/>
								</Form.Item>
							</Section>
							<Section>
								<Container>
									<SignInTemplate.AntdButton
										size="large"
										style={{ width: "50%" }}
										htmlType="submit"
										loading={submitting}
										type="primary"
										color="White"
										bg="Primary"
									>
										Iniciar Sesión
									</SignInTemplate.AntdButton>
									<SignInTemplate.NavLink to="/forgot-password">
										Olvidó su contraseña?
									</SignInTemplate.NavLink>
								</Container>
							</Section>
						</InnerForm>
					</Form>
				</Body>
			</Main>
		</SignInTemplate>
	);
}
