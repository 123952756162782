import React, { useEffect, useState } from "react";
import {
	AntdInput,
	AntdTable,
	BodyContainer,
	CompaginationDiv,
	Container,
	FilterContainer,
	HeaderContainer,
	Section,
	StepContainer,
	TextContainer,
} from "./styles";
import { COLORS, SIZES, Typo } from "../../../../../../../layout/theme";
import { Button, DatePicker, Select, Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { numberWithDotAndCommas } from "../../../../../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../../../../../utils/constants";
import { BsDownload } from "react-icons/bs";
import moment from "moment";
import { download_crm_order_detail } from "../../../../../../../../api/endpoints/orders";
import CustomAntdTooltip from "../../../../../../../common/custom-antd-tooltip/CustomAntdTooltip";

export default function Step1OrderList({
	setKey,
	handleOrderDetailStep,
	rangeDate,
	handleSelectedDate,
	selectedDistributor,
	handleSelectDistributor,
	distributors,
	selectedProvince,
	handleSelectProvince,
	provinces,
	selectedTypeOfSale,
	handleTypeOfSale,
	typeOfSales,
	selectedOrderState,
	handleOrderState,
	orderStates,
	selectedSeller,
	handleSelectSeller,
	sellers,
	handleDeleteFilters,
	orderList,
	isLoading,
	searchInfo,
	handleDecrement,
	handlePageChange,
	handleIncrement,
	paginationInputValue,
	setPaginationInputValue,
	handleChangeStep,
}) {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendiente";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activa";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviada";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Cancelada";
			case CONSTANTS.ORDER_STATE_PROCESSED:
				return "Procesada";
			case CONSTANTS.ORDER_STATE_BILLED:
				return "Facturada";
			case CONSTANTS.ORDER_STATE_REJECTED:
				return "Rechazada";
			default:
				return;
		}
	};

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					TRF
				</div>
			),
			width: 100,
			dataIndex: "orderNumId",
			render: (value, record) => (
				<Section
					onMouseOver={() => setKey(record?.id)}
					onClick={() =>
						handleChangeStep(record?.id, CONSTANTS.ERP_MODULE_ORDERS_STEP_2)
					}
				>
					<Typo level={6} type="primary">
						{value}
					</Typo>
				</Section>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Fecha
				</div>
			),
			width: 140,
			dataIndex: "created",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{moment(value).format("DD-MM-YYYY")}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Droguería
				</div>
			),
			width: 170,
			dataIndex: "distributorName",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					PDV
				</div>
			),
			width: 240,
			dataIndex: "pointOfSaleName",
			render: (value, record) =>
				record.typeOfSale !== CONSTANTS.CLIENT_TYPE_ONETRANSFER ? (
					<TextContainer
						onMouseOver={() => setKey(record?.id)}
						onClick={() =>
							handleChangeStep(record?.id, CONSTANTS.ERP_MODULE_ORDERS_STEP_3)
						}
						pointer
					>
						<Typo level={7} type="secondary">
							{value}
						</Typo>
					</TextContainer>
				) : (
					<Typo level={7} type="muted">
						{value}
					</Typo>
				),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Provincia
				</div>
			),
			width: 130,
			dataIndex: "provinceName",
			render: (value, record) => (
				<TextContainer>
					<Typo level={7} type="muted">
						{value}
					</Typo>
				</TextContainer>
			),
		},
		// {
		// 	title: () => (
		// 		<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
		// 			Localidad
		// 		</div>
		// 	),
		// 	width: 120,
		// 	dataIndex: "localityName",
		// 	render: (value, record) => (
		// 		<TextContainer>
		// 			<Typo level={7} type="muted">
		// 				{value}
		// 			</Typo>
		// 		</TextContainer>
		// 	),
		// },
		// {
		// 	title: () => (
		// 		<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
		// 			Productos
		// 		</div>
		// 	),
		// 	width: 90,
		// 	dataIndex: "qtyItems",
		// 	render: (value, record) => (
		// 		<Typo level={7} type="muted">
		// 			{value}
		// 		</Typo>
		// 	),
		// },
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Unidades
				</div>
			),
			width: 90,
			dataIndex: "qtyUnits",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		// {
		// 	title: () => (
		// 		<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
		// 			Subtotal
		// 		</div>
		// 	),
		// 	dataIndex: "subtotal",
		// 	render: (value, record) => (
		// 		<Typo level={7} type="muted">
		// 			${numberWithDotAndCommas(value)}
		// 		</Typo>
		// 	),
		// },
		// {
		// 	title: () => (
		// 		<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
		// 			Descuento
		// 		</div>
		// 	),
		// 	dataIndex: "discount",
		// 	render: (value, record) => (
		// 		<Typo level={7} type="muted">
		// 			${numberWithDotAndCommas(value)}
		// 		</Typo>
		// 	),
		// },
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Total
				</div>
			),
			dataIndex: "total",
			render: (value, record) => (
				<Typo level={7} type="muted">
					${numberWithDotAndCommas(value)}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Estado
				</div>
			),
			dataIndex: "orderState",
			render: (value, record) => (
				<>
					{record.orderSubState ? (
						<Section
							onMouseOver={() => setKey(record?.id)}
							onClick={() =>
								handleChangeStep(record?.id, CONSTANTS.ERP_MODULE_ORDERS_STEP_2)
							}
						>
							<Typo
								level={7}
								type={
									record.orderSubState === CONSTANTS.ORDER_STATE_PROCESSED
										? "secondary"
										: record.orderSubState === CONSTANTS.ORDER_STATE_BILLED
										? "terciary"
										: record.orderSubState === CONSTANTS.ORDER_STATE_REJECTED
										? "danger"
										: "muted"
								}
							>
								{selectStateToShow(record.orderSubState)}
							</Typo>
						</Section>
					) : (
						<Typo level={7} type="muted">
							{selectStateToShow(value)}
						</Typo>
					)}
				</>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Vendedor
				</div>
			),
			dataIndex: "sellerId",
			render: (value, record) => (
				<TextContainer
					onMouseOver={() => setKey(record?.id)}
					onClick={() =>
						handleChangeStep(record?.id, CONSTANTS.ERP_MODULE_ORDERS_STEP_4)
					}
					pointer
				>
					<Typo level={7} type="secondary">
						{record.sellerFirstName} {record.sellerLastName}
					</Typo>
				</TextContainer>
			),
		},
	];

	const handleDownloadFile = async () => {
		setLoading(true);
		try {
			const _orderData = orderList.map((o) => ({
				trf: o?.orderNumId,
				pointOfSaleName: o?.pointOfSaleName,
				pointOfSaleAddress: o?.pointOfSaleAddress,
				distributorName: o?.distributorName,
				orderDate: o?.created,
				orderQtyItems: o?.qtyItems,
				orderQtyUnits: o?.qtyUnits,
				orderSubtotal: o?.subtotal,
				orderTotal: o?.total,
				orderDiscount: o?.discount,
				orderProvince: o?.provinceName,
				orderLocality: o?.localityName,
				orderState: o?.orderState,
				orderSubState: o?.orderSubState,
			}));

			const _orderProducts = [];

			const res = await download_crm_order_detail({
				orderToExport: _orderData,
				orderProductsToExport: _orderProducts,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName =
				"Listado_ordenes_ERP_" + moment().format("DD-MM-YYYY") + ".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<StepContainer>
			<HeaderContainer>
				<Container>
					<FilterContainer>
						<Typo type="secondary" level={6}>
							Fecha
						</Typo>
						{rangeDate ? (
							<DatePicker.RangePicker
								format="DD/MM/YYYY"
								value={rangeDate}
								placeholder={["Desde", "Hasta"]}
								onChange={(e) => handleSelectedDate(e)}
								style={{ width: 200 }}
								disabled={isLoading}
							/>
						) : (
							<DatePicker.RangePicker
								format="DD/MM/YYYY"
								onChange={(e) => handleSelectedDate(e)}
								placeholder={["Desde", "Hasta"]}
								style={{ width: 200 }}
								disabled={isLoading}
							/>
						)}
					</FilterContainer>
					<FilterContainer>
						<Typo type="secondary" level={6}>
							Droguería
						</Typo>
						<Select
							showSearch
							allowClear
							placeholder="Droguería"
							optionFilterProp="children"
							value={selectedDistributor}
							onChange={(e, o) => handleSelectDistributor(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={distributors}
							style={{ width: 200 }}
							disabled={isLoading}
						/>
					</FilterContainer>
				</Container>
				<Container>
					<FilterContainer>
						<Typo type="secondary" level={6}>
							Provincia
						</Typo>
						<Select
							showSearch
							allowClear
							placeholder="Provincia"
							optionFilterProp="children"
							value={selectedProvince}
							onChange={(e, o) => handleSelectProvince(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={provinces}
							style={{ width: 200 }}
							disabled={isLoading}
						/>
					</FilterContainer>
					<FilterContainer>
						<Typo type="secondary" level={6}>
							Tipo de venta
						</Typo>
						<Select
							showSearch
							allowClear
							placeholder="Tipo de venta"
							optionFilterProp="children"
							value={selectedTypeOfSale}
							onChange={(e, o) => handleTypeOfSale(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={typeOfSales}
							style={{ width: 200 }}
							disabled={isLoading}
						/>
					</FilterContainer>
				</Container>
				<Container>
					<FilterContainer>
						<Typo type="secondary" level={6}>
							Estado
						</Typo>
						<Select
							showSearch
							allowClear
							placeholder="Estado de orden"
							optionFilterProp="children"
							value={selectedOrderState}
							onChange={(e, o) => handleOrderState(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={orderStates}
							style={{ width: 200 }}
							disabled={isLoading}
						/>
					</FilterContainer>
					<FilterContainer>
						<Typo type="secondary" level={6}>
							Vendedor
						</Typo>
						<Select
							showSearch
							allowClear
							placeholder="Vendedor"
							optionFilterProp="children"
							value={selectedSeller}
							onChange={(e, o) => handleSelectSeller(e, o)}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={sellers}
							style={{ width: 200 }}
							disabled={isLoading}
						/>
					</FilterContainer>
				</Container>
				<Container
					style={{ justifyContent: "flex-end", width: "200px", marginLeft: 18 }}
				>
					<FilterContainer>
						<Button
							type="primary"
							onClick={handleDeleteFilters}
							disabled={isLoading}
							style={{ width: 110 }}
						>
							Borrar filtros
						</Button>
						{loading ? (
							<Spin size="small" />
						) : (
							<CustomAntdTooltip
								placement="top"
								width="105px"
								tooltipContent={[
									{
										body: "Exportar datos",
									},
								]}
							>
								<BsDownload
									size={18}
									color={COLORS.Secondary}
									style={{ cursor: "pointer" }}
									onClick={handleDownloadFile}
								/>
							</CustomAntdTooltip>
						)}
					</FilterContainer>
				</Container>
				{/* <Container style={{ height: "100%" }}>
					{loading ? (
						<Spin size="small" />
					) : (
						<CustomAntdTooltip
							placement="left"
							width="105px"
							tooltipContent={[
								{
									body: "Exportar datos",
								},
							]}
						>
							<BsDownload
								size={18}
								color={COLORS.Secondary}
								style={{ cursor: "pointer" }}
								onClick={handleDownloadFile}
							/>
						</CustomAntdTooltip>
					)}
				</Container> */}
			</HeaderContainer>
			<BodyContainer>
				<AntdTable
					dataSource={orderList}
					loading={isLoading}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
				{searchInfo && searchInfo.totalOrders > 0 && (
					<CompaginationDiv>
						{searchInfo.actualPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						<AntdInput
							step={1}
							min={1}
							max={searchInfo.totalPages}
							onPressEnter={(e) => handlePageChange(e)}
							onChange={(e) =>
								setPaginationInputValue(e.target.value.toString())
							}
							defaultValue={paginationInputValue.toString()}
							value={paginationInputValue.toString()}
							addonAfter={`/ ${searchInfo.totalPages}`}
							style={{
								width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
							}}
						/>
						{searchInfo.actualPage < searchInfo.totalPages && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={
									searchInfo.actualPage === searchInfo.totalPages ? true : false
								}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</BodyContainer>
		</StepContainer>
	);
}
