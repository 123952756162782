import { Route, Routes } from "react-router-dom";
import LayoutContainer from "../containers/layout/LayoutContainer";
import { Home, RedirectPage, UsersPage } from "../pages/common";
import Cart from "../pages/buyer/cart/Cart";
import {
	BrandList,
	CreateBrand,
	CreateProduct,
	DashboardSeller,
	ProductList,
	UpdateBrand,
	UpdateProduct,
	SellerAccountInformation,
	Sales,
	SaleDetail,
	Items,
	Billing,
	LaboratoryRoutesPage,
	LaboratoryUserRoutesPage,
	UserDiscountsPage,
	LaboratoryAuditory,
	LaboratoryProductDiscountList,
	SellerWeb,
	SellerExpo,
	LaboratoryExpoEventList,
	LaboratoryCreateExpoEvent,
	LaboratoryUpdateExpoEvent,
	WebSellersDiscountsPage,
	LaboratoryCouponsList,
	LaboratoryDistributorResponse,
	GeneralConfig,
	ModuleErp,
	HomeMobile,
	CreateCombo,
	UpdateCombo,
	ModuleOrderStates,
} from "../pages/seller";
import AccountInformation from "../pages/buyer/account/update/AccountInformation";
import ProductDetail from "../pages/seller/product-detail/ProductDetail";
import UserProfile from "../pages/common/user-profile/UserProfile";
import {
	CreateDistributor,
	CreateMarketProduct,
	CreateMegaDistributor,
	DistributorList,
	IndexPage,
	MarketProductsIndex,
	RequestList,
	UpdateDistributor,
	UpdateLaboratory,
	UpdateMarketProduct,
	UpdateMegaDistributor,
	UpdateRequest,
	Import,
	DashboardOneMarket,
	Auditory,
	MarketUsersPage,
	MarketOrders,
	MarketOrderDetail,
	OneMarketSellerPage,
	MobileRequestList,
	UpdateMobileRequest,
	AsignPage,
	MarketPointOfSalePage,
	ClientPosList,
	UpdateClientPointOfSale,
	CreateClientPointOfSale,
	FeaturedIndex,
	GeneralPointsOfSale,
	PointOfSaleHistoryDetail,
	ImportGeneralPos,
	MarketRoutesPage,
	MarketUserRoutesPage,
	MarketUserDiscountsPage,
	SendedFiles,
	AuthUsersOneMarket,
	DistributorResponse,
	CreateFeaturedProduct,
	UpdateFeaturedProduct,
	MarketProductsDiscountsPage,
	MarketWebSellersDiscountsPage,
	MarketExpoEventList,
	MarketCreateExpoEvent,
	MarketUpdateExpoEvent,
	MarketCouponsList,
	MarketCreateCoupon,
	MarketUpdateCoupon,
	MarketMobileConfigsPage,
	MarketGeneralConfig,
	MarketGeneralPos,
	MarketGeneralPosToValidate,
	CreateMarketComboProduct,
	UpdateMarketComboProduct,
} from "../pages/admin";
import ProtectedRoute from "./ProtectedRoute";
import { useFilter } from "../../contexts/filterContext";
import { CONSTANTS } from "../../utils/constants";

export default function Main({ userdata, clientUser }) {
	const {
		setSelectedSeller,
		setSellerDashboardDisplay,
		setOrdersLabSellerId,
		setUserSellerId,
	} = useFilter();

	const getRedirectPath = () => {
		if (userdata.isOneMarket) {
			return "/generalConfig";
		}
		if (userdata.isSeller && userdata.p_shop && !userdata.p_mobile) {
			setOrdersLabSellerId(clientUser?.id);
			return "/admin/laboratorySellerWeb";
		}
		if (userdata.isSeller && userdata.p_shop && userdata.p_mobile) {
			setOrdersLabSellerId(clientUser?.id);
			return "/admin/expoSellerWeb";
		}
		if (userdata.isSeller && !userdata.p_shop && userdata.p_mobile) {
			setSelectedSeller(clientUser?.id);
			setOrdersLabSellerId(clientUser?.id);
			setSellerDashboardDisplay(CONSTANTS.DASHBOARD_MOBILE);
			return "/homeMobile";
		}
		if (userdata.isSeller && userdata.p_client) {
			return "/erp";
		}
		if (
			userdata.isOneMarket &&
			!userdata.p_client &&
			!userdata.p_users &&
			!userdata.p_products &&
			userdata.p_dashboard &&
			userdata.p_sales &&
			userdata.p_shop
		) {
			setUserSellerId(clientUser?.id);
		}
		return null;
	};

	const redirectPath = getRedirectPath();

	return (
		<LayoutContainer>
			<Routes>
				<Route
					path="/"
					element={
						<ProtectedRoute
							isAllowed={!redirectPath}
							redirectPath={redirectPath || "/"}
						>
							<Home />
						</ProtectedRoute>
					}
				/>

				<Route path="/homeMobile">
					<Route index element={<HomeMobile clientId={userdata?.clientId} />} />
				</Route>

				<Route path="/product">
					<Route path=":id" element={<ProductDetail />} />
				</Route>

				<Route
					path="/generalConfig"
					element={
						<ProtectedRoute
							isAllowed={
								userdata &&
								userdata.p_client &&
								(userdata.p_products || userdata.p_users)
							}
						/>
					}
				>
					<Route
						index
						element={
							userdata.isOneMarket ? (
								<MarketGeneralConfig userdata={userdata} />
							) : userdata.isSeller ? (
								<GeneralConfig
									userdata={userdata}
									clientContract={clientUser?.client?.contract}
									clientId={userdata?.clientId}
									clientName={clientUser?.client?.slug}
								/>
							) : (
								<Home />
							)
						}
					/>
				</Route>

				<Route
					path="/erp"
					element={
						<ProtectedRoute
							isAllowed={userdata && userdata.p_dashboard && userdata.p_sales}
						/>
					}
				>
					<Route
						index
						element={
							userdata.isOneMarket ? (
								<Home />
							) : userdata.isSeller ? (
								<ModuleErp
									userdata={userdata}
									clientContract={clientUser?.client?.contract}
									clientId={userdata?.clientId}
								/>
							) : (
								<Home />
							)
						}
					/>
				</Route>

				<Route
					path="/generalPosConfig"
					element={
						<ProtectedRoute isAllowed={userdata && userdata.isOneMarket} />
					}
				>
					<Route
						index
						element={
							userdata.isOneMarket &&
							((userdata.p_client && userdata.p_products) ||
								(!userdata.p_client &&
									!userdata.p_products &&
									userdata.p_dashboard)) ? (
								<MarketGeneralPos userdata={userdata} />
							) : userdata.isSeller ? (
								<Home />
							) : (
								<Home />
							)
						}
					/>
				</Route>

				<Route
					path="/generalPosToValidate"
					element={
						<ProtectedRoute isAllowed={userdata && userdata.isOneMarket} />
					}
				>
					<Route
						index
						element={
							userdata.isOneMarket &&
							((userdata.p_client && userdata.p_products) ||
								(!userdata.p_client &&
									!userdata.p_products &&
									userdata.p_dashboard)) ? (
								<MarketGeneralPosToValidate userdata={userdata} />
							) : userdata.isSeller ? (
								<Home />
							) : (
								<Home />
							)
						}
					/>
				</Route>

				<Route
					path="/orderStates"
					element={<ProtectedRoute isAllowed={userdata} />}
				>
					<Route
						index
						element={
							userdata.isOneMarket ? (
								<Home />
							) : userdata.isSeller ? (
								<ModuleOrderStates
									userdata={userdata}
									clientContract={clientUser?.client?.contract}
									clientId={userdata?.clientId}
								/>
							) : (
								<Home />
							)
						}
					/>
				</Route>

				<Route path="/redirect" element={<RedirectPage />}>
					<Route index path=":uri" element={<RedirectPage />} />
				</Route>

				<Route
					exact
					path="/cart"
					element={
						<ProtectedRoute
							redirectPath="/"
							isAllowed={
								userdata &&
								(userdata.p_shop || (!userdata.p_shop && userdata.p_mobile))
							}
						>
							<Cart />
						</ProtectedRoute>
					}
				/>

				<Route
					exact
					path="/marketSellerPage"
					element={
						<ProtectedRoute
							redirectPath="/"
							isAllowed={userdata.isOneMarket && userdata.p_shop}
						>
							<OneMarketSellerPage />
						</ProtectedRoute>
					}
				/>

				<Route exact path="/profile" element={<UserProfile />} />

				<Route
					path="/admin/*"
					element={
						<Routes>
							<Route
								path="/items"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_products}
									/>
								}
							>
								<Route
									index
									element={<Items clientId={userdata?.clientId} />}
								/>
							</Route>

							<Route
								path="/products"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_products}
									/>
								}
							>
								<Route index element={<ProductList />} />
								<Route exact path="create" element={<CreateProduct />} />
								<Route path=":id" element={<UpdateProduct />} />
								<Route
									exact
									path="createCombo"
									element={<CreateCombo clientId={userdata?.clientId} />}
								/>
								<Route
									path="/products/combo/:id"
									element={<UpdateCombo clientId={userdata?.clientId} />}
								/>
							</Route>

							<Route
								path="/laboratorySellerWeb"
								element={
									<ProtectedRoute
										redirectPath="/"
										isAllowed={
											userdata.isSeller && userdata.p_shop && !userdata.p_mobile
										}
									>
										<SellerWeb clientId={userdata?.clientId} />
									</ProtectedRoute>
								}
							/>

							<Route
								path="/expoSellerWeb"
								element={
									<ProtectedRoute
										redirectPath="/"
										isAllowed={
											userdata.isSeller && userdata.p_shop && userdata.p_mobile
										}
									>
										<SellerExpo clientId={userdata?.clientId} />
									</ProtectedRoute>
								}
							/>

							<Route
								path="/brands"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_products}
									/>
								}
							>
								<Route index element={<BrandList />} />
								<Route exact path="create" element={<CreateBrand />} />
								<Route path=":id" element={<UpdateBrand />} />
							</Route>

							<Route
								path="/ventas"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_sales}
									/>
								}
							>
								<Route
									index
									element={<Sales clientId={userdata?.clientId} />}
								/>
								<Route
									path=":id"
									element={<SaleDetail clientId={userdata?.clientId} />}
								/>
							</Route>

							<Route
								path="/laboratoryAuditory"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_sales}
									/>
								}
							>
								<Route
									index
									element={<LaboratoryAuditory clientId={userdata?.clientId} />}
								/>
							</Route>

							<Route
								path="/users"
								element={
									<ProtectedRoute isAllowed={userdata && userdata.p_users} />
								}
							>
								<Route
									index
									element={<UsersPage clientId={userdata.clientId} />}
								/>
							</Route>

							<Route
								path="/dashboard"
								element={
									<ProtectedRoute
										isAllowed={
											userdata &&
											((!userdata.isOneMarket &&
												userdata.p_dashboard &&
												userdata.p_sales) ||
												(userdata.isOneMarket &&
													userdata.p_products &&
													userdata.p_dashboard &&
													userdata.p_sales) ||
												(userdata.isOneMarket &&
													userdata.p_client &&
													userdata.p_products &&
													userdata.p_sales) ||
												(userdata.isOneMarket &&
													userdata.p_dashboard &&
													userdata.p_sales &&
													!userdata.p_shop &&
													!userdata.p_mobile))
										}
									/>
								}
							>
								<Route
									index
									element={
										userdata.isOneMarket ? (
											<DashboardOneMarket />
										) : userdata.isSeller ? (
											<DashboardSeller
												clientId={userdata?.clientId}
												clientUser={clientUser}
											/>
										) : (
											<Home />
										)
									}
								/>
							</Route>

							<Route
								path="/account"
								element={
									<ProtectedRoute isAllowed={userdata && userdata.p_client} />
								}
							>
								<Route
									index
									element={
										userdata.isSeller ? (
											<SellerAccountInformation />
										) : (
											<AccountInformation />
										)
									}
								/>
								<Route
									exact
									path="/account/billing"
									element={
										userdata?.isSeller ? (
											<Billing clientId={userdata?.clientId} />
										) : (
											<Home />
										)
									}
								/>
							</Route>

							<Route
								path="/distributors"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<DistributorList />} />
								<Route exact path="create" element={<CreateDistributor />} />
								<Route path=":id" element={<UpdateDistributor />} />
								<Route exact path="asign" element={<AsignPage />} />
							</Route>

							<Route
								path="/generalPointsOfSale"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_products &&
											userdata.p_dashboard
										}
									/>
								}
							>
								<Route index element={<GeneralPointsOfSale />} />
								<Route path=":id" element={<PointOfSaleHistoryDetail />} />
							</Route>

							<Route
								path="/marketProductsIndex"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketProductsIndex />} />
								<Route
									exact
									path="createMarketProduct"
									element={<CreateMarketProduct />}
								/>
								<Route
									exact
									path="createMarketComboProduct"
									element={<CreateMarketComboProduct />}
								/>
								<Route
									exact
									path="/marketProductsIndex/marketProduct/:id"
									element={<UpdateMarketProduct />}
								/>
								<Route
									exact
									path="/marketProductsIndex/marketComboProduct/:id"
									element={<UpdateMarketComboProduct />}
								/>
							</Route>

							<Route
								path="/marketPos"
								element={
									<ProtectedRoute
										isAllowed={
											(userdata.isOneMarket && userdata.p_users) ||
											(userdata.isOneMarket &&
												userdata.p_client &&
												userdata.p_products &&
												userdata.p_sales)
										}
									/>
								}
							>
								<Route index element={<MarketPointOfSalePage />} />
								<Route
									exact
									path="/marketPos/clientPos/:id"
									element={<ClientPosList />}
								/>
								<Route
									exact
									path="/marketPos/clientPointOfSale/:id"
									element={<UpdateClientPointOfSale />}
								/>
								<Route
									exact
									path="/marketPos/clientPointOfSale/create"
									element={<CreateClientPointOfSale />}
								/>
							</Route>

							<Route
								path="/marketUsers"
								element={
									<ProtectedRoute
										isAllowed={
											(userdata.isOneMarket && userdata.p_users) ||
											(userdata.isOneMarket &&
												userdata.p_client &&
												userdata.p_products &&
												userdata.p_sales)
										}
									/>
								}
							>
								<Route
									index
									element={
										<MarketUsersPage
											userdata={userdata}
											clientUser={clientUser}
										/>
									}
								/>
							</Route>

							<Route
								path="/import"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<Import />} />
							</Route>

							<Route
								path="/marketWebSellers"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketWebSellersDiscountsPage />} />
							</Route>

							<Route
								path="/importGeneralPos"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_products &&
											userdata.p_dashboard
										}
									/>
								}
							>
								<Route index element={<ImportGeneralPos />} />
							</Route>

							<Route
								path="/marketRoutes"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketRoutesPage />} />
							</Route>

							<Route
								path="/marketUserRoutes"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketUserRoutesPage />} />
							</Route>

							<Route
								path="/marketUserDiscounts"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketUserDiscountsPage />} />
							</Route>

							<Route
								path="/marketProductDiscounts"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketProductsDiscountsPage />} />
							</Route>

							<Route
								path="/marketMobileConfigs"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketMobileConfigsPage />} />
							</Route>

							<Route
								path="/laboratoryRoutes"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={
										<LaboratoryRoutesPage clientId={userdata?.clientId} />
									}
								/>
							</Route>

							<Route
								path="/laboratoryEvents"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={
										<LaboratoryExpoEventList clientId={userdata?.clientId} />
									}
								/>
								<Route
									exact
									path="create"
									element={
										<LaboratoryCreateExpoEvent clientId={userdata?.clientId} />
									}
								/>
								<Route
									path=":id"
									element={
										<LaboratoryUpdateExpoEvent clientId={userdata?.clientId} />
									}
								/>
							</Route>

							<Route
								path="/laboratoryCoupons"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={
										<LaboratoryCouponsList clientId={userdata?.clientId} />
									}
								/>
							</Route>

							<Route
								path="/laboratoryUserRoutes"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={
										<LaboratoryUserRoutesPage clientId={userdata?.clientId} />
									}
								/>
							</Route>

							<Route
								path="/laboratoryProductDiscounts"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={
										<LaboratoryProductDiscountList
											clientId={userdata?.clientId}
										/>
									}
								/>
							</Route>

							<Route
								path="/laboratoryUserDiscounts"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={<UserDiscountsPage clientId={userdata?.clientId} />}
								/>
							</Route>

							<Route
								path="/laboratoryWebSellerDiscounts"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={
										<WebSellersDiscountsPage clientId={userdata?.clientId} />
									}
								/>
							</Route>

							<Route
								path="/featuredSettings"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<FeaturedIndex />} />
								<Route
									exact
									path="/featuredSettings/createFeaturedProducts"
									element={<CreateFeaturedProduct />}
								/>
								<Route
									exact
									path="/featuredSettings/featuredProduct/:id"
									element={<UpdateFeaturedProduct />}
								/>
							</Route>

							<Route
								path="/distributorsIndex"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<IndexPage />} />
								<Route
									exact
									path="createMegaDistributor"
									element={<CreateMegaDistributor />}
								/>
								<Route
									exact
									path="/distributorsIndex/megaDistributor/:id"
									element={<UpdateMegaDistributor />}
								/>
								<Route
									exact
									path="/distributorsIndex/laboratory/:id"
									element={<UpdateLaboratory />}
								/>
							</Route>

							<Route
								path="/auditory"
								element={
									<ProtectedRoute
										isAllowed={
											(userdata.isOneMarket &&
												userdata.p_products &&
												userdata.p_dashboard &&
												userdata.p_sales) ||
											(userdata.isOneMarket &&
												userdata.p_dashboard &&
												userdata.p_sales &&
												!userdata.p_shop &&
												!userdata.p_mobile)
										}
									/>
								}
							>
								<Route index element={<Auditory />} />
							</Route>

							<Route
								path="/sendedFiles"
								element={<ProtectedRoute isAllowed={userdata.isOneMarket} />}
							>
								<Route index element={<SendedFiles />} />
							</Route>

							<Route
								path="/marketAuths"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<AuthUsersOneMarket />} />
							</Route>

							<Route
								path="/distributorResponse"
								element={<ProtectedRoute isAllowed={userdata.isOneMarket} />}
							>
								<Route index element={<DistributorResponse />} />
							</Route>

							<Route
								path="/laboratoryDistributorResponse"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={
										<LaboratoryDistributorResponse
											clientId={userdata?.clientId}
										/>
									}
								/>
							</Route>

							<Route
								path="/expoEventOT"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketExpoEventList />} />
								<Route
									exact
									path="create"
									element={<MarketCreateExpoEvent />}
								/>
								<Route path=":id" element={<MarketUpdateExpoEvent />} />
							</Route>

							<Route
								path="/marketCoupons"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketCouponsList />} />
								<Route exact path="create" element={<MarketCreateCoupon />} />
								<Route path=":id" element={<MarketUpdateCoupon />} />
							</Route>

							<Route path="*" element={<div>404</div>} />
						</Routes>
					}
				/>

				<Route
					path="*"
					element={
						<main style={{ padding: "1rem" }}>
							<p>There's nothing here!</p>
						</main>
					}
				/>
			</Routes>
		</LayoutContainer>
	);
}
