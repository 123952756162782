import { Space, Table, Button, Modal } from "antd";
import { useEffect, useState } from "react";
import Image from "../../../../../common/image/Image";
import { COLORS, Typo } from "../../../../../layout/theme";
import ProductQuantity from "../product-quantity/ProductQuantity";
import {
	CartDelete,
	ContainerDiv,
	PriceAndDiscountContainer,
	PriceInfoContainer,
} from "./styles";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { calculatePrice } from "../../../../../../utils/calculatePrice";
import { FaImages } from "react-icons/fa";
import ProductModal from "../product-modal/ProductModal";

export default function ProductTable({
	data,
	onAdd,
	onDecrement,
	onIncrement,
	onDelete,
	columns,
	scrollData = null,
	fetch,
	onChange,
}) {
	const [show, setShow] = useState(false);
	const [modalID, setModalID] = useState(null);

	const handleDetail = (id) => {
		setShow(true);
		setModalID(id);
	};

	const handleOk = () => {
		setShow(false);
	};

	const handleCancel = () => {
		setShow(false);
	};

	const defColumns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Ean</div>,
			dataIndex: "ean13",
			key: "ean13",
			width: 100,
			render: (value, record, index) => (
				<Typo type="secondary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			width: 100,
			align: "center",
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<div key={`${value}`} style={{ cursor: "pointer" }}>
					<Image
						width={40}
						height={40}
						src={value}
						alt={record.title}
						onClick={() => handleDetail(record.id)}
					/>
					{modalID === record.id && show === true ? (
						<Modal
							visible={show}
							onOk={handleOk}
							onCancel={handleCancel}
							width={900}
							centered={true}
							footer={null}
							destroyOnClose={true}
						>
							<ProductModal
								id={record.id}
								minQuantity={record.minQuantity}
								maxQuantity={record.maxQuantity}
							/>
						</Modal>
					) : null}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Marca</div>,
			dataIndex: "brandName",
			key: "brandName",
			render: (value, record, index) => (
				<Typo type="secondary" level={6}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			key: "title",
			render: (value, record, index) => (
				<Typo type="mediumGrey" level={6}>
					{value} {record.presentationName}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "clientGeneralDiscount",
			width: 150,
			key: "clientGeneralDiscount",
			render: (v, r, idx) => (
				<div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
					{!r.isComboProduct && (
						<PriceAndDiscountContainer>
							<Typo type="success" level={6}>
								-{numberWithDotAndCommas(r.discount.toFixed(2))}% PSL
							</Typo>
							<Typo type="secondary" level={7} translate="no">
								-
								{numberWithDotAndCommas(
									(100 - (r.offerPrice * 100) / r.price).toFixed(2)
								)}
								% PVP
							</Typo>
						</PriceAndDiscountContainer>
					)}
					{r.isComboProduct && (
						<PriceAndDiscountContainer>
							<Typo type="success" level={7}>
								{r.notes}
							</Typo>
						</PriceAndDiscountContainer>
					)}
					{parseInt(r.triggerQty) !== 0 && (
						<Typo
							level={8}
							type="primary"
							style={{ textAlign: "start" }}
							translate="no"
						>
							{r.triggerQty} un. o más -{r.triggerDiscount}% OFF
						</Typo>
					)}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Cantidad pedido</div>,
			dataIndex: "quantity",
			key: "quantity",
			width: 180,
			render: (quantity, record, index) => (
				<ProductQuantity
					product={{ quantity, record, index }}
					onChange={onChange}
				/>
			),
		},
	];

	return (
		<ContainerDiv>
			<Table
				showHeader={true}
				columns={columns ? columns : defColumns}
				dataSource={data}
				pagination={false}
				rowKey={(record) => record.id}
				scroll={scrollData}
			/>
		</ContainerDiv>
	);
}
