import React, { useEffect, useState } from "react";
import {
	AntdTable,
	BodyContainer,
	Container,
	HeaderContainer,
	Section,
	StepContainer,
} from "./styles";
import { IoIosArrowRoundBack } from "react-icons/io";
import { COLORS, SIZES, Typo } from "../../../../../../../layout/theme";
import Image from "../../../../../../../common/image/Image";
import DEFAULTUSER from "../../../../../../../../assets/images/SinImagenDeUsuario.jpeg";
import moment from "moment";
import { BsDownload } from "react-icons/bs";
import { Spin } from "antd";
import { download_crm_user_detail } from "../../../../../../../../api/endpoints/users";
import CustomAntdTooltip from "../../../../../../../common/custom-antd-tooltip/CustomAntdTooltip";

export default function Step4UserDetail({ stepData, handleBackStep }) {
	const [toggleTable, setToggleTable] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const columns = [
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Punto de venta
				</div>
			),
			width: 300,
			dataIndex: "name",
			render: (value, record) => <Typo level={7}>{value}</Typo>,
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Cuit
				</div>
			),
			width: 110,
			dataIndex: "cuit",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Razón social
				</div>
			),
			dataIndex: "businessName",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Dirección
				</div>
			),
			dataIndex: "address",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Provincia
				</div>
			),
			dataIndex: "province",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Localidad
				</div>
			),
			dataIndex: "locality",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Pedidos
				</div>
			),
			width: 80,
			dataIndex: "totalOrders",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey, fontSize: SIZES.level7 }}>
					Fecha última orden
				</div>
			),
			dataIndex: "lastOrderDate",
			render: (value, record) => (
				<Typo level={7} type="muted">
					{moment(value).format("DD-MM-YYYY")}
				</Typo>
			),
		},
	];

	const handleDownloadFile = async () => {
		setLoading(true);
		try {
			const _userData = [
				{
					name: stepData?.firstName + " " + stepData?.lastName,
					email: stepData?.email,
					phone: stepData?.phone,
					totalRoutes: stepData?.routes?.length,
					totalOrders: stepData?.routes?.reduce(
						(accumulator, currentValue) =>
							accumulator + currentValue.totalOrders,
						0
					),
				},
			];

			const _userRoute = stepData?.routes?.map((r) => ({
				name: r.name,
				cuit: r.cuit,
				businessName: r.businessName,
				address: r.address,
				province: r.province,
				locality: r.locality,
				totalOrders: r.totalOrders,
				lastOrderDate: moment(r.lastOrderDate).format("DD-MM-YYYY"),
			}));

			const res = await download_crm_user_detail({
				userToExport: _userData,
				userRoutesToExport: _userRoute,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName =
				"Ficha_Vendedor_" +
				stepData?.firstName +
				"_" +
				stepData?.lastName +
				"_" +
				moment().format("DD-MM-YYYY") +
				".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<StepContainer>
			<HeaderContainer>
				<Section
					onClick={() => handleBackStep(1)}
					style={{ cursor: "pointer", justifyContent: "start" }}
				>
					<IoIosArrowRoundBack size={19} color={COLORS.Secondary} />
					<Typo type="secondary" level={7}>
						Volver
					</Typo>
				</Section>
				<Section style={{ marginLeft: -180 }}>
					<Typo type="dark" level={6}>
						Ficha vendedor {stepData?.firstName} {stepData?.lastName}
					</Typo>
				</Section>
				<Section>
					{loading ? (
						<Spin size="small" />
					) : (
						<CustomAntdTooltip
							placement="left"
							width="105px"
							tooltipContent={[
								{
									body: "Exportar datos",
								},
							]}
						>
							<BsDownload
								size={18}
								color={COLORS.Secondary}
								style={{ cursor: "pointer" }}
								onClick={handleDownloadFile}
							/>
						</CustomAntdTooltip>
					)}
				</Section>
			</HeaderContainer>
			<BodyContainer>
				<Container
					style={{ width: 100, justifyContent: "center", alignItems: "center" }}
				>
					<Image
						width={60}
						height={60}
						src={stepData?.photoUrl || DEFAULTUSER}
						alt={stepData?.firstName}
						borderRadius={40}
					/>
				</Container>
				<Container>
					<Section>
						<Typo type="grey" level={7}>
							Nombre:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.firstName}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Apellido:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.lastName}
						</Typo>
					</Section>
				</Container>
				<Container>
					<Section>
						<Typo type="grey" level={7}>
							Correo electrónico:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.email}
						</Typo>
					</Section>
					<Section>
						<Typo type="grey" level={7}>
							Teléfono:
						</Typo>
						<Typo type="mediumGrey" level={7}>
							{stepData?.phone}
						</Typo>
					</Section>
				</Container>
				<Container>
					{stepData?.routes?.length > 0 && (
						<Section>
							<Typo type="grey" level={7}>
								Clientes asociados:
							</Typo>
							<Typo
								type="secondary"
								level={7}
								style={{ cursor: "pointer" }}
								onClick={() => setToggleTable((prev) => !prev)}
							>
								{stepData?.routes?.length}
							</Typo>
						</Section>
					)}
					{stepData?.orders?.length > 0 && (
						<Section>
							<Typo type="grey" level={7}>
								Ordenes totales:
							</Typo>
							<Typo type="secondary" level={7}>
								{stepData?.orders?.length}
							</Typo>
						</Section>
					)}
				</Container>
			</BodyContainer>
			{toggleTable && stepData?.routes?.length > 0 && (
				<Section style={{ padding: "10px 10px 0 10px" }}>
					<Typo type="grey" level={7}>
						Clientes asociados
					</Typo>
				</Section>
			)}
			{toggleTable && stepData?.routes?.length > 0 && (
				<BodyContainer>
					<AntdTable
						dataSource={stepData?.routes || []}
						loading={!stepData}
						columns={columns}
						pagination={false}
						scroll={{ y: "1020px" }}
					/>
				</BodyContainer>
			)}
		</StepContainer>
	);
}
