import React, { useEffect, useState } from "react";
import { Inner } from "./styles";
import { CONSTANTS } from "../../../../../../utils/constants";
import { get_seller_erp_orders_dapper } from "../../../../../../api/endpoints/orders";
import moment from "moment";
import Step1OrderList from "./components/step1-order-list/Step1OrderList";
import Step2OrderDetail from "./components/step2-order-detail/Step2OrderDetail";
import Step3PosDetail from "./components/step3-pos-detail/Step3PosDetail";
import Step4UserDetail from "./components/step4-user-detail/Step4UserDetail";

export default function ModuleOrders({ clientId }) {
	const [step, setStep] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [orderList, setOrderList] = useState(null);
	const [sellerList, setSellerList] = useState(null);
	const [detailOrderList, setDetailOrderList] = useState(null);
	const [posCodeList, setPosCodeList] = useState(null);
	const [pointOfSaleList, setPointOfSaleList] = useState(null);
	const [routesList, setRoutesList] = useState(null);
	const [searchInfo, setSearchInfo] = useState(null);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [provinces, setProvinces] = useState(null);
	const [orderStates, setOrderStates] = useState(null);
	const [typeOfSales, setTypeOfSales] = useState(null);
	const [sellers, setSellers] = useState(null);
	const [pointsOfSale, setPointsOfSale] = useState(null);
	const [distributors, setDistributors] = useState(null);
	const [selectedDistributor, setSelectedDistributor] = useState(null);
	const [selectedProvince, setSelectedProvince] = useState(null);
	const [selectedTypeOfSale, setSelectedTypeOfSale] = useState(null);
	const [selectedPointOfSale, setSelectedPointOfSale] = useState(null);
	const [selectedSeller, setSelectedSeller] = useState(null);
	const [selectedOrderState, setSelectedOrderState] = useState(null);
	const [rangeDate, setRangeDate] = useState(null);
	const [dateFrom, setDateFrom] = useState(null);
	const [dateTo, setDateTo] = useState(null);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [actualPage, setActualPage] = useState(1);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [ordersQty, setOrdersQty] = useState(null);
	const [key, setKey] = useState(null);
	const [stepData, setStepData] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const fetch_orders = async (skipValue) => {
		setIsLoading(true);
		try {
			const res = await get_seller_erp_orders_dapper(
				clientId,
				selectedDistributor,
				selectedPointOfSale,
				selectedSeller,
				selectedProvince,
				selectedTypeOfSale,
				selectedOrderState,
				dateFrom,
				dateTo,
				skipValue,
				page
			);
			const resTypesOfOrders = JSON.parse(res?.paginationInfo?.typesOfOrders);
			const resStatesOfOrders = JSON.parse(res?.paginationInfo?.statesOfOrders);
			const resProvinceOfOrders = JSON.parse(
				res?.paginationInfo?.provinceOfOrders
			);
			const resPharmacyOfOrders = JSON.parse(
				res?.paginationInfo?.pharmacyOfOrders
			);
			const resSellerOfOrders = JSON.parse(res?.paginationInfo?.sellerOfOrders);
			const resDistributorOfOrders = JSON.parse(
				res?.paginationInfo?.distributorOfOrders
			);

			setOrderList(res?.orders);
			setPointOfSaleList(res?.pointsOfSale);
			setSellerList(res?.sellers);
			setDetailOrderList(res?.orderDetails);
			setPosCodeList(res?.pointOfSaleCodes);
			setRoutesList(res?.sellerRoutes);
			setSearchInfo(res?.paginationInfo);

			setPaginationInputValue(res?.paginationInfo?.actualPage?.toString());
			setTypeOfSales(
				resTypesOfOrders?.map((r) => ({
					value: r.TypeOfSale,
					label: r.TypeOfSale,
				}))
			);
			setOrderStates(
				resStatesOfOrders?.map((r) => ({
					value: r.OrderState,
					label: selectStateToShow(r.OrderState),
				}))
			);
			setProvinces(
				resProvinceOfOrders?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);
			setPointsOfSale(
				resPharmacyOfOrders?.map((r) => ({
					value: r.PharmacyId,
					label: r.PharmacyName,
				}))
			);
			setSellers(
				resSellerOfOrders?.map((r) => ({
					value: r.SellerId,
					label: r.SellerFirstName + " " + r.SellerLastName,
				}))
			);
			setDistributors(
				resDistributorOfOrders?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setHeaderInfo({
				totalActive: res?.paginationInfo.totalActive,
				totalPending: res?.paginationInfo.totalPending,
				totalSended: res?.paginationInfo.totalSended,
				totalCanceled: res?.paginationInfo.totalCanceled,
			});

			if (res && res?.paginationInfo.totalActive !== ordersQty) {
				setOrdersQty(res?.paginationInfo.totalActive);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setActualPage(1);
		setPage(CONSTANTS.PAGE_LARGE_SIZE);
		fetch_orders(1);
	}, [
		selectedDistributor,
		selectedPointOfSale,
		selectedSeller,
		selectedProvince,
		selectedTypeOfSale,
		selectedOrderState,
		rangeDate,
	]);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendientes";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activas";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviadas";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Canceladas";
			case CONSTANTS.ORDER_STATE_PROCESSED:
				return "Procesada";

			default:
				return;
		}
	};

	const handleSelectDistributor = (e, o) => {
		setSelectedDistributor(e);
	};

	const handleSelectProvince = (e, o) => {
		setSelectedProvince(e);
	};

	const handleTypeOfSale = (value, o) => {
		setSelectedTypeOfSale(value);
	};

	const handleOrderState = (e, o) => {
		setSelectedOrderState(e);
	};

	const handleSelectSeller = (e, o) => {
		setSelectedSeller(e);
	};

	const handleSelectedDate = (dateRange) => {
		const firstDate = moment(dateRange[0]).format("MM-DD-YYYY").toString();
		const lastDate = moment(dateRange[1]).format("MM-DD-YYYY").toString();
		setDateFrom(firstDate);
		setDateTo(lastDate);
		setRangeDate([dateRange[0], dateRange[1]]);
	};

	const handleDeleteFilters = () => {
		setSelectedDistributor(null);
		setSelectedProvince(null);
		setRangeDate(null);
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_orders(cp);
			setActualPage(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_orders(cp);
			setActualPage(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_orders(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const handleOrderDetailStep = (id, stepNumber) => {
		const auxOrder = orderList.filter((o) => o.id === id)[0];
		const step2 = {
			orderId: id,
			orderDate: auxOrder?.created,
			orderQtyItems: auxOrder?.qtyItems,
			orderQtyUnits: auxOrder?.qtyUnits,
			orderSubtotal: auxOrder?.subtotal,
			orderTotal: auxOrder?.total,
			orderDiscount: auxOrder?.discount,
			orderProvince: auxOrder?.provinceName,
			orderLocality: auxOrder?.localityName,
			orderNumId: auxOrder?.orderNumId,
			orderState: auxOrder?.orderState,
			orderSubState: auxOrder?.orderSubState,
			pointOfSaleId: auxOrder?.pointOfSaleId,
			pointOfSaleName: auxOrder?.pointOfSaleName,
			pointOfSaleAddress: auxOrder?.pointOfSaleAddress,
			distributorId: auxOrder?.distributorId,
			distributorName: auxOrder?.distributorName,
			productList: detailOrderList.filter((o) => o.orderId === id),
		};
		setStepData(step2);
		setStep(stepNumber);
	};

	const handlePosDetailStep = (id, stepNumber) => {
		const auxOrder = orderList?.filter((o) => o.id === id)[0];
		const auxPos = pointOfSaleList?.filter(
			(p) => p.id === auxOrder?.pointOfSaleId
		)[0];
		const step3 = {
			id: auxPos?.id,
			name: auxPos?.name,
			cuit: auxPos?.cuit,
			businessName: auxPos?.businessName,
			glnAnmat: auxPos?.glnAnmat,
			address: auxPos?.address,
			postalCode: auxPos?.postalCode,
			contactName: auxPos?.contactName,
			contactEmail: auxPos?.contactEmail,
			contactPhone: auxPos?.contactPhone,
			province: auxPos?.provinceName,
			locality: auxPos?.localityName,
			posCodes: posCodeList.filter((c) => c.pointOfSaleId === auxPos?.id),
		};
		setStepData(step3);
		setStep(stepNumber);
	};

	const handleUserDetailStep = (id, stepNumber) => {
		const auxOrder = orderList?.filter((o) => o.id === id)[0];
		const auxUser = sellerList?.filter((p) => p.id === auxOrder?.sellerId)[0];
		const step4 = {
			id: auxUser?.id,
			firstName: auxUser?.firstName,
			lastName: auxUser?.lastName,
			photoUrl: auxUser?.photoUrl,
			email: auxUser?.email,
			phone: auxUser?.phone,
			routes: routesList
				.filter((r) => r.userId === auxUser.id)
				?.sort((a, b) => b.totalOrders - a.totalOrders),
			orders: orderList.filter((r) => r.sellerId === auxUser.id),
		};
		setStepData(step4);
		setStep(stepNumber);
	};

	const handleChangeStep = (id, toStep) => {
		switch (toStep) {
			case CONSTANTS.ERP_MODULE_ORDERS_STEP_2:
				handleOrderDetailStep(id, 2);
				break;
			case CONSTANTS.ERP_MODULE_ORDERS_STEP_3:
				handlePosDetailStep(id, 3);
				break;
			case CONSTANTS.ERP_MODULE_ORDERS_STEP_4:
				handleUserDetailStep(id, 4);
				break;
			default:
				break;
		}
	};

	const handleBackStep = (stepNumber) => {
		setStep(stepNumber);
	};

	return (
		<Inner>
			{step === 1 && (
				<Step1OrderList
					setKey={setKey}
					handleOrderDetailStep={handleOrderDetailStep}
					rangeDate={rangeDate}
					handleSelectedDate={handleSelectedDate}
					selectedDistributor={selectedDistributor}
					handleSelectDistributor={handleSelectDistributor}
					distributors={distributors}
					selectedProvince={selectedProvince}
					handleSelectProvince={handleSelectProvince}
					provinces={provinces}
					selectedTypeOfSale={selectedTypeOfSale}
					handleTypeOfSale={handleTypeOfSale}
					typeOfSales={typeOfSales}
					selectedOrderState={selectedOrderState}
					handleOrderState={handleOrderState}
					orderStates={orderStates}
					selectedSeller={selectedSeller}
					handleSelectSeller={handleSelectSeller}
					sellers={sellers}
					handleDeleteFilters={handleDeleteFilters}
					orderList={orderList}
					isLoading={isLoading}
					searchInfo={searchInfo}
					handleDecrement={handleDecrement}
					handlePageChange={handlePageChange}
					handleIncrement={handleIncrement}
					paginationInputValue={paginationInputValue}
					setPaginationInputValue={setPaginationInputValue}
					handleChangeStep={handleChangeStep}
				/>
			)}
			{step === 2 && (
				<Step2OrderDetail stepData={stepData} handleBackStep={handleBackStep} />
			)}
			{step === 3 && (
				<Step3PosDetail stepData={stepData} handleBackStep={handleBackStep} />
			)}
			{step === 4 && (
				<Step4UserDetail stepData={stepData} handleBackStep={handleBackStep} />
			)}
		</Inner>
	);
}
