import { Button } from "antd";
import styled from "styled-components/macro";
import { COLORS, SIZES, WEIGHT } from "../../../../../layout/theme";

export const Inner = styled.div`
	margin-top: -10px;
	width: 100%;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
	.ant-card-bordered {
		border: none;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-wrap: nowrap;
	gap: 20px;
`;

export const BottomContainer = styled.div`
	display: flex;
`;

export const LeftContainer = styled.div`
	flex: 1 1 40%;
`;

export const LeftCarousel = styled.div`
	width: 400px;
	align-content: center;
`;

export const MainImageContainer = styled.div`
	text-align: center;
`;

export const ImageContainer = styled.div``;

export const ImageFooter = styled.div`
	text-align: center;
`;

export const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 300px;
	box-shadow: rgb(50 50 93 / 5%) 20px 0px 14px 1px,
		rgb(0 0 0 / 5%) -19px 20px 20px -10px;
	flex: 1 1 50%;
	padding: 30px 40px 10px 40px;

	.slick-list {
		padding: 0 30px;
	}
`;

export const Info = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const InfoRow = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: flex-end;
`;

export const PriceTextOff = styled.p`
	font-weight: 700;
	writing-mode: vertical-lr;
	font-size: 11px;
	margin-right: -10px;
	margin-top: 12px;
	color: ${COLORS.MediumGrey};
	transform: rotateZ(180deg);
	width: 10px;
`;

export const PriceTextOffContainer = styled.div`
	display: flex;
	align-items: center;
	scale: 1.3;
`;

export const SectionItems = styled.div`
	display: flex;
	flex-direction: column;
`;

export const InfoSection = styled.div`
	margin-top: 7px;
`;

export const Section = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 40px;
`;

export const SubSection = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;

export const Line = styled.div`
	text-decoration: line-through rgba(0, 0, 0, 0.2);
	align-items: flex-start;
	align-content: center;
	justify-content: center;
	display: flex;
`;

export const InfoDescription = styled.div`
	height: 50px;
	margin-top: 10px;
	position: relative;
`;

export const ContainerShadow = styled.div``;

export const InfoIva = styled.div`
	padding-top: 5px;
	gap: 10px;
	max-width: 300px;
	font-size: 10px;
	color: ${COLORS.Default};
`;

export const SectionOffer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin-bottom: 10px;
`;

export const TriggerQtyContainer = styled.div`
	color: ${COLORS.StrongGreen};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level7};
	text-align: center;
`;

export const OffContainer = styled.div`
	writing-mode: vertical-lr;
	font-size: 8px;
	transform: rotateZ(180deg);
`;

export const SpecialOfferContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const MinQtyContainer = styled.div`
	color: ${COLORS.Secondary};
	font-weight: ${WEIGHT.Bold};
	font-size: ${SIZES.level7};
	text-align: center;
	margin-bottom: 2px;

	@media only screen and (min-width: 760px) and (max-width: 1180px) {
		font-size: 10px;
	}
`;
