import React, { useState } from "react";
import {
	Container,
	ModuleContainer,
	Section,
	StyledLi,
	StyledUl,
} from "./styles";
import {
	MdOutlineAppRegistration,
	MdOutlineBusiness,
	MdOutlineChecklist,
	MdOutlinePlace,
} from "react-icons/md";
import { COLORS, Typo } from "../../../../../layout/theme";
import { Link } from "react-router-dom";
import { BiCoinStack, BiImport, BiPlusMedical } from "react-icons/bi";
import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import { FaHeadset } from "react-icons/fa";
import { CONSTANTS } from "../../../../../../utils/constants";
import { TbDeviceMobile } from "react-icons/tb";
import { useFilter } from "../../../../../../contexts/filterContext";

export default function ModuleErp({ clientContract }) {
	const { setAdminUsersType } = useFilter();
	const [showSubMenu, setShowSubMenu] = useState(null);

	return (
		<Section>
			<ModuleContainer>
				<Container>
					<MdOutlineBusiness style={{ color: COLORS.Secondary }} size={50} />
					<Typo type="Grey" level={4}>
						ERP
					</Typo>
					<Typo type="mediumGrey" weight="light">
						Administra tu aplicación y los datos de tu negocio
					</Typo>
				</Container>
				<StyledUl>
					{clientContract &&
						clientContract.activeMobileApp &&
						clientContract.mobileUsersZoneType ===
							CONSTANTS.USER_ZONE_TYPE_ROUTE && (
							<Link to="/admin/laboratoryRoutes?uri=generalConfig">
								<StyledLi>
									<BiPlusMedical color={COLORS.Secondary} />
									<Typo type="secondary" level={13}>
										Puntos de venta
									</Typo>
								</StyledLi>
							</Link>
						)}
					{clientContract &&
						clientContract.activeMobileApp &&
						clientContract.mobileUsersZoneType ===
							CONSTANTS.USER_ZONE_TYPE_ROUTE && (
							<Link to="/admin/laboratoryUserRoutes?uri=generalConfig">
								<StyledLi>
									<MdOutlinePlace color={COLORS.Secondary} />
									<Typo type="secondary" level={13}>
										Rutas
									</Typo>
								</StyledLi>
							</Link>
						)}
					<Link
						to="/admin/users?uri=generalConfig"
						onClick={() => setAdminUsersType(CONSTANTS.USERS_MOBILE)}
					>
						<StyledLi>
							<UserOutlined style={{ color: COLORS.Secondary }} />
							<Typo type="secondary" level={13}>
								Usuarios
							</Typo>
						</StyledLi>
					</Link>
					<Link to="/admin/items?uri=generalConfig">
						<StyledLi>
							<MdOutlineChecklist color={COLORS.Secondary} />
							<Typo type="secondary" level={13}>
								Productos activos
							</Typo>
						</StyledLi>
					</Link>
					{clientContract &&
						(clientContract.activeMobileApp ||
							clientContract.activeWebSeller) && (
							<StyledLi
								onClick={() => setShowSubMenu(CONSTANTS.MODULE_ERP_MOBILE)}
								style={{ cursor: "pointer" }}
							>
								<MdOutlineAppRegistration color={COLORS.Secondary} />
								<Typo type="secondary" level={13}>
									Dinámicas / Ofertas
								</Typo>
							</StyledLi>
						)}
					{clientContract && clientContract.activeExpoSection && (
						<Link to="/admin/laboratoryEvents?uri=generalConfig">
							<StyledLi>
								<CalendarOutlined style={{ color: COLORS.Secondary }} />
								<Typo type="secondary" level={13}>
									Eventos
								</Typo>
							</StyledLi>
						</Link>
					)}
					<Link to="/admin/laboratoryAuditory?uri=generalConfig">
						<StyledLi>
							<BiImport color={COLORS.Secondary} />
							<Typo type="secondary" level={13}>
								Auditoría pedidos
							</Typo>
						</StyledLi>
					</Link>
				</StyledUl>
			</ModuleContainer>
			{showSubMenu === CONSTANTS.MODULE_ERP_MOBILE && (
				<ModuleContainer>
					<Container>
						<MdOutlineAppRegistration
							style={{ color: COLORS.Secondary }}
							size={50}
						/>
						<Typo type="Grey" level={4}>
							Dinámicas
						</Typo>
						<Typo type="mediumGrey" weight="light">
							Sección para administrar las dinámicas de tus aplicaciones
						</Typo>
					</Container>
					<StyledUl>
						{clientContract && clientContract.activeMobileApp && (
							<Link to="/admin/laboratoryProductDiscounts?uri=generalConfig">
								<StyledLi>
									<BiCoinStack color={COLORS.Secondary} />
									<Typo type="secondary" level={13}>
										Dinámicas mobile
									</Typo>
								</StyledLi>
							</Link>
						)}
						{clientContract &&
							clientContract.activeMobileApp &&
							clientContract.activeDiscountsForUsers && (
								<Link to="/admin/laboratoryUserDiscounts?uri=generalConfig">
									<StyledLi>
										<TbDeviceMobile color={COLORS.Secondary} />
										<Typo type="secondary" level={13}>
											Dinámicas vendedores mobile
										</Typo>
									</StyledLi>
								</Link>
							)}
						{clientContract && clientContract.activeWebSeller && (
							<Link to="/admin/laboratoryWebSellerDiscounts?uri=generalConfig">
								<StyledLi>
									<FaHeadset color={COLORS.Secondary} />
									<Typo type="secondary" level={13}>
										Dinámicas callcenter
									</Typo>
								</StyledLi>
							</Link>
						)}
					</StyledUl>
				</ModuleContainer>
			)}
		</Section>
	);
}
