import styled, { keyframes } from "styled-components/macro";
import { COLORS } from "../../../../../layout/theme";

const spin = keyframes`
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
`;

export const Spinner = styled.div`
	border: 4px solid rgba(0, 0, 0, 0.1);
	width: 36px;
	height: 36px;
	border-radius: 50%;
	border-left-color: #09f;

	animation: ${spin} 1s ease infinite;
`;

export const ModalContainer = styled.div`
	display: flex;
	width: 100%;
	height: 620px;
	overflow-x: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px #e3e3e3;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: ${COLORS.Grey};
		border-radius: 10px;
	}
`;
