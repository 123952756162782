import React from "react";
import Layout from "../../../../layout/base/Layout";
import Image from "../../../../common/image/Image";
import { Col } from "antd";
import { Link } from "react-router-dom";
import MarketLogo from "../../../../../assets/images/logo.png";
import MenuIcon from "../../../../../assets/images/bars-solid.svg";

export default function MarketHeader({
	scrollDirection,
	userdata,
	location,
	templateProducts,
	qty,
	setShowDrawer,
	showDrawer,
	browserInfo,
}) {
	return (
		<Layout.LayoutHeader
			justify="space-evenly"
			align="middle"
			scroll={scrollDirection}
		>
			<Col md={3} lg={3} xl={3}>
				<Link to="/">
					<Image src={MarketLogo} alt="Logo Market" height={45} />
				</Link>
			</Col>

			<Col md={2} lg={2} xl={2}>
				<Layout.HeaderAction
					onClick={() => setShowDrawer(!showDrawer)}
					style={{
						width: "100px",
					}}
				>
					<Layout.HeaderMenuIcon>
						<Layout.HeaderMenuIconContainer firefox={browserInfo.firefox}>
							<Image
								src={MenuIcon}
								alt="Menu"
								style={{
									height: "50%",
									width: "50%",
									marginLeft: "10px",
								}}
							/>
							<Layout.HeaderMenuParagraph>Menú</Layout.HeaderMenuParagraph>
						</Layout.HeaderMenuIconContainer>
					</Layout.HeaderMenuIcon>
				</Layout.HeaderAction>
			</Col>
		</Layout.LayoutHeader>
	);
}
